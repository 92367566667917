const displayConditions = {
   formWithPreFilledData: {
      isURL: ["url", "link"],
      isBoolean: [],
      isDate: [],
      isNumber: {}, // EX:  status: { min: "0", max: "100" },
      isList: [], // List with compiled options.
      isSuggestionsList: {}, // List with fixed & compiled options. EX: method: ["course", "project", "tutorial"]
      isLimitedList: {}, // List with no input box and fixed options. EX: // type: ["- Select one -", "step", "goal", "hold"],
      // priority: ["0", "10", "9", "8", "7", "6", "5", "4", "3", "2", "1"],
      isFixedCompiledList: [], // List with no input box and compiled options.
      isOtherKeyFixedCompiledList: {}, // Select with options to display and save different keys & values. exp
      // msup: { keyToSave: "identifier", keyToDisplay: "title" },
      // asup: { keyToSave: "identifier", keyToDisplay: "title" },

      forSlideButton: [],
      protectedHidden: [
         "id",
         "date",
         "guid",
         "modified",
         "slug",
         "status",
         "type",
         "title",
         "excerpt",
         "author",
         "featured_media",
         "comment_status",
         "sticky",
         "template",
         "format",
         "categories",
         "tag_names",
         "jetpack_featured_media_url",
         "jetpack-related-posts",
         "_links",
         "dependencies"
      ],

      protectedVisible: [
         // "PROTECT-ALL",
         "title",
         "content",
         "createdAt",
         "updatedAt",
         "slug",
         "identifier",
         "masterLibraryID"
      ]
   },
   emptyForm: {
      isURL: ["url", "demonstratedskillurl"],
      isBoolean: [],
      isDate: ["createdAt", "updatedAt", "start", "acomp"],
      isNumber: {
         labTime: { min: "0", max: "" },
         lectureTime: { min: "0", max: "" },
         status: { min: "0", max: "100" }
      },
      isList: ["author", "platform", "tag_names"], // List with compiled options.
      isSuggestionsList: { method: ["course", "project", "tutorial"] }, // List with fixed & compiled options.
      isLimitedList: {
         type: ["- Select one -", "step", "goal", "hold"],
         priority: ["0", "10", "9", "8", "7", "6", "5", "4", "3", "2", "1"]
      }, // List with no input box and fixed options.
      isFixedCompiledList: [], // List with no input box and compiled options.
      isOtherKeyFixedCompiledList: {
         msup: { keyToSave: "identifier", keyToDisplay: "title" },
         asup: { keyToSave: "identifier", keyToDisplay: "title" }
      }, // Select with options to display and save different keys & values.
      protectedHidden: ["identifier"],
      protectedVisible: []
   },
   jsonUpload: {
      isURL: ["url", "demonstratedskillurl"],
      isBoolean: [],
      isDate: ["createdAt", "updatedAt", "start", "acomp"],
      isNumber: {
         labTime: { min: "0", max: "" },
         lectureTime: { min: "0", max: "" },
         status: { min: "0", max: "100" }
      },
      isList: ["author", "platform", "tag_names"], // List with compiled options.
      isSuggestionsList: { method: ["course", "project", "tutorial"] }, // List with fixed & compiled options.
      isLimitedList: {
         type: ["- Select one -", "step", "goal", "hold"],
         priority: ["0", "10", "9", "8", "7", "6", "5", "4", "3", "2", "1"]
      }, // List with no input box and fixed options.
      isFixedCompiledList: [], // List with no input box and compiled options.
      isOtherKeyFixedCompiledList: {
         msup: {
            keyToSave: "identifier",
            keyToDisplay: "title",
            groupByField: "type"
         },
         asup: {
            keyToSave: "identifier",
            keyToDisplay: "title",
            groupByField: "type"
         }
      }, // Select with options to display and save different keys & values.
      protectedHidden: ["msup", "asup", "id", "identifier", "createdAt"],
      protectedVisible: []
   }
};

export default displayConditions;
