import styles from "./Header.module.scss";
import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import JSLogo from "../JSLogo/JSLogo";
import LearnModeToggleButton from "../LearnModeToggleButton/LearnModeToggleButton";
// import SubscribeCTA from "../SubscribeCTA/SubscribeCTA";
import CardPrimary from "../../UI/Cards/CardPrimary/CardPrimary";
import PushButton from "../../UI/Buttons/PushButton/PushButton";
import useViewport from "../../Hooks/useViewport";
import Navbar from "../Navbar/Navbar";
import WordSearchTool from "../../Components/WordSearchTool/WordSearchTool";
import { jsCheatSheetDataActions } from "../../store/jsCheatSheetDataSlice";

function Header(props) {
   const dispatch = useDispatch();
   const [logoToHeaderBar, setLogoToHeaderBar] = useState(false);
   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
   const { content } = useSelector((state) => state.contentData);
   const { jsCheatSheetMetadata, expandedItems } = useSelector(
      (state) => state.jsCheatSheetData
   );
   const [width] = useViewport();
   const { welcomeScrollPosition } = useSelector(
      (state) => state.scrollPosition
   );

   const [logoTitleSize, setLogoTitleSize] = useState(100);
   const [initialWelcomePositionTop, setInitialWelcomePositionTop] =
      useState(false);
   let xChange = logoTitleSize - 100;
   let yChange = logoTitleSize - 79;
   let logoTitleTransform = {
      transform:
         "scale(" +
         logoTitleSize +
         "%) translate(" +
         xChange +
         "%, " +
         yChange +
         "%)"
   };
   const navLinks = [];

   const toggleOpenAll = (e) => {
      if (e.target.value === "open") {
         dispatch(
            jsCheatSheetDataActions.expandAllItems({
               section: "jsCheatSheet-main",
               idArray: jsCheatSheetMetadata.id
            })
         );
      } else {
         dispatch(
            jsCheatSheetDataActions.expandAllItems({
               section: "jsCheatSheet-main",
               idArray: []
            })
         );
      }
   };

   if (content) {
      for (const value of Object.values(content)) {
         if (
            Object.hasOwn(value, "active") &&
            value.active &&
            value.active.replace(" ", "") !== ""
         ) {
            if (
               Object.hasOwn(value, "addToNavMenu") &&
               value.addToNavMenu &&
               value.addToNavMenu.replace(" ", "") !== ""
            )
               navLinks.push(value);
         }
      }
   }

   const mobileMenuButtonHandler = () => {
      setMobileMenuOpen(!mobileMenuOpen);
   };

   // const startDemoButtonHandler = () => {
   //   navigate("/demo");
   // };

   const menuModalToggleStyles = mobileMenuOpen
      ? { opacity: "1", pointerEvents: "all", left: "0" }
      : {};

   useEffect(() => {
      xChange = logoTitleSize - 112.5;
      yChange = logoTitleSize - 75;
      logoTitleTransform = {
         transform:
            "scale(" +
            logoTitleSize +
            "%) translate(" +
            xChange +
            "%, " +
            yChange +
            "%)"
      };
   }, [logoTitleSize]);

   useEffect(() => {
      if (
         !initialWelcomePositionTop &&
         welcomeScrollPosition &&
         Object.hasOwn(welcomeScrollPosition, "top")
      ) {
         setInitialWelcomePositionTop(64);
      } else if (
         welcomeScrollPosition &&
         Object.hasOwn(welcomeScrollPosition, "top")
      ) {
         let newSizeValue =
            100 + (welcomeScrollPosition.top - initialWelcomePositionTop) / 5;

         if (newSizeValue >= 100) newSizeValue = 100;
         if (newSizeValue <= 33 && width > 600) newSizeValue = 33;
         if (newSizeValue <= 85 && width <= 600) newSizeValue = 33;
         setLogoTitleSize(newSizeValue);

         if (newSizeValue <= 67) {
            setLogoToHeaderBar("header");
         } else if (newSizeValue <= 74) {
            setLogoToHeaderBar("medium");
         } else if (logoToHeaderBar) {
            setLogoToHeaderBar(false);
         }
      }
   }, [welcomeScrollPosition, initialWelcomePositionTop]);

   return (
      <div
         id="spt-header"
         className={
            styles.outerwrap +
            " " +
            styles["logo-to-outerwrap-" + logoToHeaderBar]
         }
      >
         <div
            className={
               styles["logo-title-outerwrap"] +
               " " +
               styles["logo-to-header-" + logoToHeaderBar]
            }
            style={logoTitleTransform}
         >
            <div className={styles["header-title-container"]}>
               <div className={styles["logo-wrap"]}>
                  <JSLogo />
               </div>
               <a href="/" alt="">
                  <div className={styles["title-wrap"]}>
                     <h1
                        className={
                           styles["spt-title"] + " " + styles["first-word"]
                        }
                     >
                        JS
                     </h1>
                     <h1
                        className={
                           styles["spt-title"] + " " + styles["second-word"]
                        }
                     >
                        Cheat Sheet
                     </h1>
                     <h3 className={styles["spt-subtitle"]}>
                        All of JS All On One Page
                     </h3>
                  </div>
               </a>
            </div>
         </div>
         <div className={styles["header-functions-container"]}>
            <LearnModeToggleButton bigDisplayButton={true} />
            <WordSearchTool />
            <div className={styles["toggle-open-button-container"]}>
               {jsCheatSheetMetadata && jsCheatSheetMetadata.id && (
                  <Fragment>
                     {(((Object.keys(expandedItems).length === 0 ||
                        Object.values(expandedItems).forEach((group) => {
                           return group.length <= 0;
                        })) &&
                        jsCheatSheetMetadata.id.length > 0) ||
                        (Object.values(expandedItems).some((group) => {
                           return !(
                              group.length === jsCheatSheetMetadata.id.length
                           );
                        }) &&
                           jsCheatSheetMetadata.id.length > 0)) && (
                        <PushButton
                           inputOrButton="button"
                           id="open-all-btn"
                           colorType="primary"
                           value="open"
                           data-value="open"
                           size="small"
                           data-dir="-1"
                           styles={{
                              background: "var(--jscs-color-accent)",
                              color: "var(--jscs-color-background)",
                              margin: "auto",
                              minWidth: "min-content",
                              font: "var(--jscs--font-heading-2)",
                              fontVariant: "small-caps",
                              fontSize: "50%",
                              padding: "0.4em 1.5em",
                              borderRadius: "0",
                              maxWidth: "min-content",
                              lineHeight: "1em ",
                              fontWeight: "700",
                              flexGrow: "1"
                           }}
                           onClick={toggleOpenAll}
                        >
                           Open All
                        </PushButton>
                     )}

                     {Object.values(expandedItems).some((group) => {
                        return group.length > 0;
                     }) && (
                        <PushButton
                           inputOrButton="button"
                           id="close-all-btn"
                           colorType="primary"
                           value="close"
                           data-value="open"
                           size="small"
                           data-dir="-1"
                           styles={{
                              background: "var(--jscs-color-accent)",
                              color: "var(--jscs-color-background)",
                              margin: "auto",
                              minWidth: "min-content",
                              font: "var(--jscs--font-heading-2)",
                              fontVariant: "small-caps",
                              fontSize: "50%",
                              padding: "0.4em 1.5em",
                              borderRadius: "0",
                              maxWidth: "min-content",
                              lineHeight: "1em ",
                              fontWeight: "700",
                              flexGrow: "1"
                           }}
                           onClick={toggleOpenAll}
                        >
                           Close All
                        </PushButton>
                     )}
                  </Fragment>
               )}
            </div>
            <div className={styles["nav-container"]}>
               <Navbar
                  navLinks={props.navLinks}
                  aboutIsActive={props.aboutIsActive}
                  goalsIsActive={props.goalsIsActive}
                  stepsIsActive={props.stepsIsActive}
                  holdsIsActive={props.holdsIsActive}
                  controlsIsActive={props.controlsIsActive}
                  socialIsActive={props.socialIsActive}
               />
            </div>
            <button
               className={styles["mobile-menu-button"]}
               onClick={mobileMenuButtonHandler}
            >
               <span></span>
               <span></span>
               <span></span>
            </button>
         </div>
         <div className={styles["menu-modal"]} style={menuModalToggleStyles}>
            <CardPrimary styles={{ maxHeight: "100vh", maxWidth: "100vw" }}>
               <div className={styles["modal-nav-container"]}>
                  <Navbar
                     navLinks={props.navLinks}
                     aboutIsActive={props.aboutIsActive}
                     goalsIsActive={props.goalsIsActive}
                     stepsIsActive={props.stepsIsActive}
                     holdsIsActive={props.holdsIsActive}
                     controlsIsActive={props.controlsIsActive}
                     socialIsActive={props.socialIsActive}
                     linkOnClick={mobileMenuButtonHandler}
                  />
               </div>
               <button onClick={mobileMenuButtonHandler}>Close</button>
            </CardPrimary>
         </div>
      </div>
   );
}

export default Header;
