import { jsCheatSheetData as jsCheatSheetDataFunction } from "../storage/jsCheatSheetDB.js";
// import runGetSchemaForJSCheatSheetItems from "./runGetSchemaForJSCheatSheetItems";

export default async function GatherJSCheatSheetData(
   jsCheatSheetItemSchema,
   user,
   passedJSCheatSheet
) {
   const jsCheatSheetData = {};
   jsCheatSheetData.jsCheatSheet = {};

   let rawJSCheatSheetFromDB = [];
   rawJSCheatSheetFromDB = passedJSCheatSheet
      ? passedJSCheatSheet
      : await jsCheatSheetDataFunction(user);

   jsCheatSheetData.schema = jsCheatSheetItemSchema
      ? jsCheatSheetItemSchema
      : await rawJSCheatSheetFromDB[0];

   let currentFilters = null;
   // If local data storage components are active.
   // if (dataFromStorage) {
   //   historyDataFromStorage = dataFromStorage.toolsHistory;
   //   currentFilters = dataFromStorage.currentFilters;
   // }

   const jsCheatSheetFromDB = [];
   for (const value of rawJSCheatSheetFromDB) {
      const newValue = { ...value };

      ///////////////////////////////////////
      /// Flatten Items with "render" child
      for (const [innerKey, innerValue] of Object.entries(newValue)) {
         if (innerValue.constructor === Object) {
            if (Object.keys(innerValue).includes("rendered"))
               newValue[innerKey] = innerValue.rendered;
         }
      }

      //////////////////////////////////////
      /// Add any missing keys.
      Object.keys(jsCheatSheetData.schema).forEach((schemaKey) => {
         if (!Object.hasOwn(newValue, schemaKey)) newValue[schemaKey] = "";
      });

      jsCheatSheetFromDB.push(newValue);
   }

   jsCheatSheetFromDB.forEach((jsCheatSheetItem) => {
      const newValue = { ...jsCheatSheetItem };
      const newId = jsCheatSheetItem.id + "-JSCSID";
      newValue.id = newId;
      jsCheatSheetData.jsCheatSheet[newId] = newValue;

      const dependencies = findDependencies(
         jsCheatSheetItem,
         jsCheatSheetFromDB
      );
      jsCheatSheetData.jsCheatSheet[newId].dependencies = dependencies;
   });

   //////////////////////////////////////
   /// Set JS version and remove JS version and About article
   jsCheatSheetData.about = {};
   jsCheatSheetData.jsVersion = "ES9";
   for (const [key, article] of Object.entries(jsCheatSheetData.jsCheatSheet)) {
      if (article.tag_names.includes("js-cheatsheet-version")) {
         jsCheatSheetData.jsVersion = article.title;
         delete jsCheatSheetData.jsCheatSheet[key];
      }
      if (article.tag_names.includes("js-cheatsheet-about")) {
         jsCheatSheetData.about = article;
         delete jsCheatSheetData.jsCheatSheet[key];
      }
      if (article.tag_names.includes("js-cheatsheet-instructions")) {
         jsCheatSheetData.instructions = article;
         delete jsCheatSheetData.jsCheatSheet[key];
      }
   }

   const ungroomedJSCheatSheetMetadata = gatherAllMetadata(
      jsCheatSheetData.jsCheatSheet
   );
   const groomedJSCheatSheetMetadata = {};
   for (const key in ungroomedJSCheatSheetMetadata) {
      const output = [];
      if (key === "tag_names") {
         const itmOutput = [];

         ungroomedJSCheatSheetMetadata[key].forEach((itm) => {
            if (itm.constructor === String) {
               itmOutput.push(...itm.split(","));
            }

            if (itm.constructor === Array) {
               itmOutput.push(...itm);
            }
         });

         const flattenedArrays = new Set(
            itmOutput.map((value) => value.trim())
         );
         const flattenedArraysOutput = Array.from(flattenedArrays);

         output.push(flattenedArraysOutput.length, flattenedArraysOutput);
      } else {
         output.push(ungroomedJSCheatSheetMetadata[key].length);
         output.push(...ungroomedJSCheatSheetMetadata[key]);
      }
      groomedJSCheatSheetMetadata[key] = [...output];
   }

   //////////////////////////////////////
   /// Alphabetic sort while building output for organized list displays
   jsCheatSheetData.jsCheatSheetMetadata = {};
   for (const [key, value] of Object.entries(groomedJSCheatSheetMetadata)) {
      jsCheatSheetData.jsCheatSheetMetadata[key] = [
         value[0],
         ...value.splice(1).sort((a, b) => {
            let stringA = a;
            let stringB = b;
            if (typeof a !== "string") stringA = a.toString();
            if (typeof b !== "string") stringB = b.toString();
            return stringA.toLowerCase().localeCompare(stringB.toLowerCase());
         })
      ];
   }

   for (const jsCheatSheetValue of Object.values(
      jsCheatSheetData.jsCheatSheet
   )) {
      if (
         Object.hasOwn(jsCheatSheetValue, "type") &&
         jsCheatSheetValue.type === "service" &&
         Object.hasOwn(jsCheatSheetValue, "slug") &&
         Object.hasOwn(jsCheatSheetValue, "iframeCustomAttributes")
      ) {
         jsCheatSheetData.serviceEmbedJSXObj[jsCheatSheetValue.slug] =
            jsCheatSheetValue.iframeCustomAttributes;
      }
   }

   //////////////////////////////////////
   /// Gather JS Cheat Sheet topics
   jsCheatSheetData.jscsTopics =
      jsCheatSheetData.jsCheatSheetMetadata.tag_names[1].filter((tagName) =>
         tagName.includes("jscs-")
      );

   // const gatherFilters = (keysArray) => {
   //   const output = new Set();
   //   keysArray.forEach((item) => output.add(item));

   //   return Array.from(output);
   // };
   // jsCheatSheetData.currentFilters =
   //   currentFilters ??
   //   gatherFilters(jsCheatSheetData.jsCheatSheetMetadata["tag_names"][1]);
   jsCheatSheetData.currentFilters = currentFilters ?? [];
   return jsCheatSheetData;
}

function gatherAllMetadata(dataObject) {
   const itemsToInclude = [
      "id",
      "date",
      "date_gmt",
      "guid",
      "modified",
      "modified_gmt",
      "slug",
      "status",
      "type",
      "link",
      "title",
      "content",
      "excerpt",
      "author",
      "featured_media",
      "comment_status",
      "ping_status",
      "sticky",
      "template",
      "format",
      "meta",
      "categories",
      "tag_names",
      "jetpack_sharing_enabled",
      "jetpack_featured_media_url",
      "_links",
      "dependencies"
   ];
   const onlyCollectID = ["markcomplete", "markforreview"];
   const addTogether = ["lectureTime", "labTime"];
   const valuesToExclude = ["undefined", "", " "];
   const outputSet = objectExtractAllValuesPerKey(
      dataObject,
      itemsToInclude,
      onlyCollectID,
      addTogether,
      valuesToExclude
   );

   return outputSet;
}

function findDependencies(subjectObj, allObjects) {
   const output = [];
   for (const value of Object.values(allObjects)) {
      if (
         Object.hasOwn(value, "identifier") &&
         value.identifier &&
         value.identifier.replaceAll(" ", "") !== "" &&
         ((value.asup &&
            value.asup.replaceAll(" ", "") !== "" &&
            value.asup.includes(subjectObj.identifier)) ||
            (value.msup &&
               value.msup !== "" &&
               value.msup.includes(subjectObj.identifier)))
      ) {
         if (
            !subjectObj.msup.includes(value.identifier) &&
            !subjectObj.asup.includes(value.identifier)
         )
            output.push(value.identifier);
      }
   }

   return output;
}

function objectExtractAllValuesPerKey(
   objectToLoop,
   itemsToInclude,
   onlyCollectID,
   addTogether,
   valuesToExclude
) {
   const outputObject = {};
   // Grab each item
   for (const i in objectToLoop) {
      // Get each item withing that question (ID, topic, answer, etc)
      for (let key in objectToLoop[i]) {
         key = key.trim();

         // Check if we are meant to include that item & the value is valid
         if (
            itemsToInclude.includes(key) &&
            !valuesToExclude.includes(objectToLoop[i][key])
         ) {
            // Handle items that need to be added together
            if (addTogether.includes(key)) {
               // if (objectToLoop[i].type.toLowerCase() === "goal") continue;
               if (!Object.hasOwn(outputObject, key)) outputObject[key] = [0];
               if (objectToLoop[i][key]) {
                  outputObject[key][0] += objectToLoop[i][key] * 1;
               }
            }
            // Handle some items by only gathering the id
            else if (onlyCollectID.includes(key)) {
               if (!Object.hasOwn(outputObject, key))
                  outputObject[key] = new Set();
               if (objectToLoop[i][key] && objectToLoop[i][key] != false) {
                  outputObject[key].add(objectToLoop[i].id);
               }
            }
            // If the value is a list, separate at the comma
            else if (
               objectToLoop[i][key] &&
               objectToLoop[i][key].constructor === String &&
               objectToLoop[i][key].indexOf(",") >= 0
            ) {
               const termArray = objectToLoop[i][key].split(",");

               // For each list item, put is in the Set (removes duplicates)

               termArray.forEach((term) => {
                  const value = term.trim().toString();

                  // Add to Set. If key Set does not exist, create it.
                  if (Object.hasOwn(outputObject, key)) {
                     outputObject[key].add(value);
                  } else {
                     outputObject[key] = new Set();
                     outputObject[key].add(value);
                  }
               });
            } // Since the value is not a string list, if the value is not an array, just add it as-is to the key Set
            else if (
               objectToLoop[i][key] &&
               objectToLoop[i][key].constructor !== Array
            ) {
               if (objectToLoop[i][key].constructor === Object) {
                  Object.values(objectToLoop[i][key]).forEach((val) => {
                     if (Object.hasOwn(outputObject, key)) {
                        outputObject[key].add(val);
                     } else {
                        outputObject[key] = new Set();
                        outputObject[key].add(val);
                     }
                  });
               } else if (objectToLoop[i][key].constructor === Boolean) {
                  const value = i.toString().trim();
                  if (Object.hasOwn(outputObject, key)) {
                     outputObject[key].add(value);
                  } else {
                     outputObject[key] = new Set();
                     outputObject[key].add(value);
                  }
               } else {
                  const value = objectToLoop[i][key].toString().trim();

                  if (Object.hasOwn(outputObject, key)) {
                     outputObject[key].add(value);
                  } else {
                     outputObject[key] = new Set();
                     outputObject[key].add(value);
                  }
               }
            } // Since the value is an array, loop to add it
            else if (
               objectToLoop[i][key] &&
               objectToLoop[i][key].constructor === Array
            ) {
               if (
                  objectToLoop[i][key].length > 0 ||
                  objectToLoop[i][key].size > 0
               ) {
                  objectToLoop[i][key].forEach((rawValue) => {
                     const value = rawValue.toString().replaceAll(" ", "");
                     // ? rawValue[0].replaceAll(" ", "").toString()
                     // : "";

                     // Check if the value is valid
                     if (!valuesToExclude.includes(value)) {
                        if (Object.hasOwn(outputObject, key)) {
                           outputObject[key].add(value);
                        } else {
                           outputObject[key] = new Set();
                           outputObject[key].add(value);
                        }
                     }
                  });
               } else {
                  // If the above does not app;y, return a Set() if it is not already there.
                  if (!Object.hasOwn(outputObject, key))
                     outputObject[key] = new Set();
               }
            }
         }
      }
   }

   for (const i in outputObject) {
      outputObject[i] = Array.from(outputObject[i]);
   }

   return outputObject;
}

// function stringToArray(tagString) {
//   if (tagString == "undefined") return [];
//   return tagString.replaceAll(" ", "").split(",");
// }
