import { Fragment } from "react";
import { useSelector } from "react-redux";
import useAddInputData from "../../../../../../Hooks/useAddInputData";
import { toTitleCase } from "../../../../../../Hooks/utility";

const InputSuggestionList = (props) => {
  const jsCheatSheetMetadata = useSelector(
    (state) => state.jsCheatSheetData.jsCheatSheetMetadata,
  );
  const addInputData = useAddInputData();
  const {
    passedKey,
    jsCheatSheetItemsObj,
    parentMasterID,
    parentsParentKey,
    parentKey,
    displayConditions,
    emptyForm,
    editedField,
    setEditedField,
  } = props;

  const key = passedKey;

  return (
    <Fragment>
      <label
        id={
          parentMasterID +
          "-" +
          parentsParentKey +
          "-" +
          parentKey +
          "-" +
          key +
          "label"
        }
        htmlFor={parentKey + "-" + key + "datalist"}
      >
        {toTitleCase(key, true, false)}
      </label>{" "}
      {/*name={parentKey + "-" + key}*/}
      <input
        type="text"
        list={parentKey + "-" + key + "datalist"}
        id={parentKey + "-" + key}
        size="50"
        autoComplete="off"
        data-category={key}
        placeholder={""}
        title={key}
        name={parentKey + "-" + key + "datalist"}
        defaultValue={jsCheatSheetItemsObj[key]}
        data-parentkey={parentKey}
        data-parentsparentkey={
          parentsParentKey ? parentsParentKey.toString() : ""
        }
        data-parentmasterid={parentMasterID}
        onChange={(e) => {
          addInputData(e, { emptyForm, editedField, setEditedField });
        }}
      />
      <datalist
        id={parentKey + "-" + key + "datalist"}
        key={parentKey + "-" + key + "datalist"}
        name={parentKey + "-" + key + "datalist"}
        defaultValue={jsCheatSheetItemsObj[key]}
        data-category={key}
        placeholder={key}
        title={key}
        data-parentkey={parentKey}
        data-parentsparentkey={
          parentsParentKey ? parentsParentKey.toString() : ""
        }
        data-parentmasterid={parentMasterID}
      >
        {Object.values(displayConditions["isSuggestionsList"][key]).map(
          (option) => (
            <option
              key={option}
              value={toTitleCase(option, true, false)}
            ></option>
          ),
        )}

        {Object.hasOwn(jsCheatSheetMetadata, key) &&
          jsCheatSheetMetadata[key].slice(1).map((option) => {
            if (!displayConditions["isSuggestionsList"][key].includes(option))
              return (
                <option
                  key={option}
                  value={toTitleCase(option, true, false)}
                ></option>
              );
          })}
      </datalist>
    </Fragment>
  );
};

export default InputSuggestionList;
