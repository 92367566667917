import { useEffect, useLayoutEffect, Fragment, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./Home.module.scss";
import CardPrimary from "../../UI/Cards/CardPrimary/CardPrimary";
import CardTransparent from "../../UI/Cards/CardTransparent/CardTransparent";
import CardSecondary from "../../UI/Cards/CardSecondary/CardSecondary";
import NotFound from "../../Components/NotFound/NotFound";
import FilterTools from "../../Components/FilterTools/FilterTools";
import ToTopButton from "../../Components/ToTopButton/ToTopButton";
import Footer from "../../Components/Footer/Footer";
import JSCheatSheetItems from "../../Components/JSCheatSheetItems/JSCheatSheetItems";
import About from "../../Components/About/About";
import Stats from "../../Components/Stats/Stats";
import Instructions from "../../Components/Instructions/Instructions";
import { ErrorBoundary } from "../../HOC/ErrorHandling/ErrorBoundary/ErrorBoundary";
import { scrollPositionActions } from "../../store/scrollPositionSlice";
import { loadingRequestsActions } from "../../store/loadingRequestsSlice";

const Home = (props) => {
   const { jsCheatSheet, inSearchMode } = useSelector(
      (state) => state.jsCheatSheetData
   );
   const angledRectangleRef = useRef();
   const dispatch = useDispatch();
   const { aboutIsActive } = useSelector((state) => state.contentData);
   const { instructionsIsActive } = useSelector((state) => state.contentData);

   ////////////////////////////////////////
   /// Effects
   ////////////////////////////////////////
   useEffect(() => {
      dispatch(loadingRequestsActions.addToLoadRequest());
      setTimeout(() => {
         dispatch(loadingRequestsActions.removeFromLoadRequest());
      }, 3000);
   }, []);

   useLayoutEffect(() => {
      const updateScrollPosition = () => {
         if (!angledRectangleRef.current) return;

         const welcomeScrollPosition =
            angledRectangleRef.current.getBoundingClientRect();

         dispatch(
            scrollPositionActions.updateWelcomeScrollPosition(
               JSON.parse(JSON.stringify(welcomeScrollPosition))
            )
         );
      };
      window.addEventListener("scroll", updateScrollPosition);
      updateScrollPosition();
      return () => window.removeEventListener("scroll", updateScrollPosition);
   }, []);

   ////////////////////////////////////////
   /// Functionality
   ////////////////////////////////////////

   ////////////////////////////////////////
   /// Output
   ////////////////////////////////////////
   return (
      <div
         className={
            styles["page-wrap"] + " " + (inSearchMode && "in-search-mode")
         }
      >
         <div className={styles["welcome-section-container"]}>
            <ErrorBoundary>
               <div className={styles["login-stats-container"]}>
                  <div className={styles["stats-outer-wrap"]}>
                     <Stats />
                  </div>
               </div>
            </ErrorBoundary>
            <div
               className={styles["angled-rectangle"]}
               ref={angledRectangleRef}
            >
               <div className={styles["background-video-wrap"]}>
                  <div className={styles["bubble"]}></div>
                  <div className={styles["bubble"]}></div>
                  <div className={styles["bubble"]}></div>
                  <div className={styles["bubble"]}></div>
                  <div className={styles["bubble"]}></div>
                  <div className={styles["bubble"]}></div>
               </div>
            </div>
            <Fragment>
               {props.notFound && (
                  <CardTransparent>
                     <ErrorBoundary>
                        <div className={styles["not-found-wrap"]}>
                           <NotFound />
                        </div>
                     </ErrorBoundary>
                  </CardTransparent>
               )}
            </Fragment>
         </div>
         {instructionsIsActive && (
            <CardSecondary>
               <ErrorBoundary>
                  <Instructions />
               </ErrorBoundary>
            </CardSecondary>
         )}
         {jsCheatSheet && (
            <div className={styles["page-content-wrap"]}>
               <CardSecondary>
                  <ErrorBoundary>
                     {props.userInitComplete && <FilterTools />}
                  </ErrorBoundary>
               </CardSecondary>

               <CardPrimary styles={{ flexGrow: "1" }}>
                  <ErrorBoundary>
                     {props.userInitComplete && (
                        <JSCheatSheetItems
                           key="jsCheatSheet-main"
                           id="jsCheatSheet-main"
                           subText="The entire JavaScript language on one page."
                           dataObjForEdit={jsCheatSheet}
                           allJSCheatSheetItems={jsCheatSheet}
                           user={props.user}
                           type={"post"}
                           maxCollapsableElmHeight={"none"}
                           noEditButton={false}
                           hideShowAllButton={true}
                        />
                     )}
                  </ErrorBoundary>
               </CardPrimary>
            </div>
         )}

         {aboutIsActive && (
            <CardPrimary>
               <ErrorBoundary>
                  <About />
               </ErrorBoundary>
            </CardPrimary>
         )}

         <ToTopButton />

         <CardSecondary>
            <ErrorBoundary>
               <Footer />
            </ErrorBoundary>
         </CardSecondary>

         {props.isDemo &&
            jsCheatSheet &&
            Object.keys(jsCheatSheet).length <= 0 && (
               <div className={styles["demo-error-modal"]}>
                  <h2>There seems to be a problem loading the demo data.</h2>
                  <p>
                     We are very sorry for the trouble. This is most likely to
                     be caused by a network issue or backend server issue.
                     Please refresh and try again. If the problem continues,
                     please email{" "}
                     <a href="mailto:general@glassinteractive.com">
                        general@glassinteractive.com
                     </a>
                     . We will get it figured out right away.
                  </p>
               </div>
            )}
      </div>
   );
};

export default Home;
