import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Stats.module.scss";
import { jsNewsFeed } from "../../storage/jsNewsAPICalls";
import Marquee from "react-fast-marquee";
import parse from "html-react-parser";

const Stats = () => {
   const { jsVersion, jscsTopics, jsCheatSheetMetadata } = useSelector(
      (state) => state.jsCheatSheetData
   );
   const totalCategories = jscsTopics ? jscsTopics.length : 0;
   const [jsNewsObj, setJSNewsObject] = useState();
   const [rotatingElm, setRotatingElm] = useState("js-version");
   const totalModes = 2;
   const jsUpdateVersion = jsVersion ? jsVersion : "ES9";
   const totalStories = jsCheatSheetMetadata && jsCheatSheetMetadata.title[0];
   const lastUpdate =
      jsCheatSheetMetadata &&
      new Date(
         Math.max(
            ...jsCheatSheetMetadata.modified.map((date) => new Date(date))
         )
      );
   const year = lastUpdate && lastUpdate.getFullYear();
   const month = lastUpdate && lastUpdate.getMonth() + 1;
   const day = lastUpdate && lastUpdate.getDate();
   const lastUpdateComplete = [month, day, year].join("/");

   // Display update date if not more than a year ago
   const displayLastUpdate =
      new Date(lastUpdate).getFullYear() > new Date().getFullYear() - 1;

   const getJSNewsFeed = async () => {
      const output = await jsNewsFeed();
      return output;
   };

   useEffect(() => {
      setTimeout(() => {
         setRotatingElm("lastUpdate");
      }, 7000);
      let ctr = 0;
      setInterval(() => {
         if (ctr % 2 === 0) {
            setRotatingElm("js-version");
         } else {
            setRotatingElm("lastUpdate");
         }
         ctr++;
      }, 15000);
   }, []);
   // function addTime(fieldName, jsCheatSheetMetadata) {
   //   let outputTime = 0;
   //   if (jsCheatSheetMetadata && Object.hasOwn(jsCheatSheetMetadata, fieldName))
   //     jsCheatSheetMetadata[fieldName].forEach((time) => {
   //       outputTime += time * 1;
   //     });
   //   return outputTime;
   // }

   useEffect(() => {
      getJSNewsFeed().then((res) => {
         const newsFeedElm = document.getElementById("newsfeed");
         if (newsFeedElm) newsFeedElm.innerHTML = parse(res);
         setJSNewsObject(res);
      });
   }, []);

   return (
      <div id="stats-container">
         <div className={styles["block"] + " " + styles["hours-wrap"]}>
            <div
               className={
                  styles["inner-block"] + " " + styles["completehrstotal"]
               }
            >
               {totalCategories}
            </div>
            <div
               className={
                  styles["inner-block"] + " " + styles["rotator-wrapper"]
               }
            >
               <div
                  className={
                     styles["inner-block"] +
                     " " +
                     styles["lastupdated"] +
                     " " +
                     styles["rotator-1"] +
                     " " +
                     styles["visible-" + (rotatingElm === "lastUpdate")]
                  }
               >
                  {lastUpdateComplete &&
                     displayLastUpdate &&
                     lastUpdateComplete.toString()}
               </div>
               <div
                  className={
                     styles["inner-block"] +
                     " " +
                     styles["hours-wrap"] +
                     " " +
                     styles["js-version"] +
                     " " +
                     styles["rotator-2"] +
                     " " +
                     styles["visible-" + (rotatingElm === "js-version")]
                  }
               >
                  <span className={styles["js-version-number"]}>
                     {jsUpdateVersion}
                  </span>
               </div>
            </div>{" "}
            <div
               className={
                  styles["inner-block"] + " " + styles["collegecredithours"]
               }
            >
               {totalModes}
            </div>
         </div>
         {jsNewsObj && (
            <div
               className={
                  styles["inner-block"] + " " + styles["news-feed-container"]
               }
            >
               <h4 className={styles["feed-title"]}>
                  Recent JS Info via
                  <a
                     href={jsNewsObj.siteLink}
                     target="_blank"
                     rel="noreferrer"
                     className={styles["feed-link"]}
                  >
                     {jsNewsObj.siteTitle}
                  </a>
               </h4>
               <div
                  className={
                     styles["inner-block"] +
                     " " +
                     styles["news-feed-article-container"]
                  }
               >
                  <Marquee
                     pauseOnHover={true}
                     speed={3}
                     gradient={true}
                     gradientWidth={100}
                  >
                     {Object.values(jsNewsObj.articles).map((article) => (
                        <p
                           key={article.title}
                           className={styles["article-detail"]}
                        >
                           <span className={styles["article-title"]}>
                              <a
                                 href={article.link}
                                 target="_blank"
                                 rel="noreferrer"
                              >
                                 {article.title}
                              </a>
                           </span>
                           <span className={styles["article-publish-date"]}>
                              {" "}
                              {new Date(article.pubDate).toDateString()}
                           </span>
                        </p>
                     ))}
                  </Marquee>
               </div>
            </div>
         )}
         {!jsNewsObj && (
            <div className={styles["block"] + " " + styles["hours-wrap"]}>
               <div
                  className={
                     styles["inner-block"] + " " + styles["storiestotal"]
                  }
               >
                  {totalStories}
               </div>
               <div
                  className={
                     styles["inner-block"] + " " + styles["collegecredithours"]
                  }
               >
                  {totalModes}
               </div>
            </div>
         )}
      </div>
   );
};
1;
export default Stats;
