import { useState, useEffect, Fragment, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./JSCheatSheetItemsList.module.scss";
import DOMPurify from "dompurify";
// import JSCheatSheetItemsSubList from "../JSCheatSheetItemsSubList/JSCheatSheetItemsSubList";
import JSCheatSheetItem from "../JSCheatSheetItem/JSCheatSheetItem";
import PushButton from "../../../UI/Buttons/PushButton/PushButton";
import ItemEditorModal from "../../ItemEditorModal/ItemEditorModal";
import { deleteDocFromDb } from "../../../storage/jsCheatSheetDB";
import { deleteContentDocFromDb } from "../../../storage/contentDB";
// import CollapsibleElm from "../../../UI/CollapsibleElm/CollapsibleElm";
import { jsCheatSheetDataActions } from "../../../store/jsCheatSheetDataSlice";
import { loadingRequestsActions } from "../../../store/loadingRequestsSlice";
import useDemoCheck from "../../../Hooks/useDemoCheck";
import { authActions } from "../../../store/authSlice";
import useJSCheatSheetListElmProperties from "../../../Hooks/useJSCheatSheetListElmProperties";
import "prismjs";

const JSCheatSheetItemsList = (props) => {
   const dispatch = useDispatch();
   let [refresh, setRefresh] = useState(1);
   const jsCheatSheetItemsObj = props.jsCheatSheetItemsObj;
   // if (Object.hasOwn(jsCheatSheetItemsObj, "dependencies"))
   const user = useSelector((state) => state.auth.user);
   const { expandedItems, currentFilters } = useSelector(
      (state) => state.jsCheatSheetData
   );
   const parentKey = props.parentKey;
   const parentsParentKey = props.parentsParentKey;
   const parentMasterID = props.parentMasterID;
   const parentMasterType = props.parentMasterType;
   const section = props.section;
   const subListLevel = props.subListLevel;
   const formInputData = useSelector((state) => state.formInputData);
   const sortOrder = props.sortOrder;
   const displayConditions = props.displayConditions;
   const onlyList = props.onlyList;
   const noEditButton = props.noEditButton;
   const allJSCheatSheetItems = props.allJSCheatSheetItems;
   const emptyForm = props.emptyForm;
   const setFormType = props.setFormType;
   const demoCheck = useDemoCheck();
   const isDemo = demoCheck();
   const [itemEditorModalJSX, setItemEditorModalJSX] = useState(false);

   const [showProtectedHidden, setShowProtectedHidden] = useState(
      props.showProtectedHidden ? props.showProtectedHidden : []
   );
   // const showProtectedHiddenRef = useRef();
   // showProtectedHiddenRef.current = showProtectedHidden;
   const [unlockProtectedVisible, setUnlockProtectedVisible] = useState(
      props.unlockProtectedVisible ? props.unlockProtectedVisible : []
   );
   // const unlockProtectedVisibleRef = useRef();
   // unlockProtectedVisibleRef.current = unlockProtectedVisible;
   const [existingFormInputValuesObj, setExistingFormInputValuesObj] = useState(
      {}
   );
   const existingFormInputValuesObjRef = useRef();
   existingFormInputValuesObjRef.current = existingFormInputValuesObj;

   ////////////////////////////////////////////////////////////////
   /// Functionality
   ////////////////////////////////////////////////////////////////
   const updateExistingFormState = (
      parentMasterID,
      parentKey,
      title,
      outputValue
   ) => {
      existingFormInputValuesObjRef.current = outputValue;

      setExistingFormInputValuesObj((prevState) => {
         const outputObj = { ...prevState };

         if (!Object.hasOwn(outputObj, parentMasterID)) {
            outputObj[parentMasterID] = { [title]: outputValue };
         } else if (
            Object.hasOwn(outputObj[parentMasterID], title) &&
            !["String", "Array", "number", "Boolean"].includes(
               outputObj[parentMasterID][title].constructor.name
            )
         ) {
            outputObj[parentMasterID][title] = {
               ...outputObj[parentMasterID][title],
               ...outputValue
            };
         } else {
            outputObj[parentMasterID][title] = outputValue;
         }

         return outputObj;
      });

      //////
   };

   // Elm properties imported to keep this slim and D.R.Y
   const elmProperties = useJSCheatSheetListElmProperties();
   const [elmPropertiesVariables, setElmPropertiesVariables] = useState({
      section,
      jsCheatSheetItemsObj,
      styles,
      parentMasterType,
      subListLevel,
      parentKey,
      parentsParentKey,
      parentMasterID,
      unlockProtectedVisible,
      onlyList,
      displayConditions,
      showProtectedHidden,
      refresh,
      allJSCheatSheetItems,
      emptyForm,
      setFormType,
      expandedItems,
      updateExistingFormState
   });
   ////

   ////////////////////////////////////////////////////////////////////////
   /// Effects
   ////////////////////////////////////////////////////////////////////////
   // useEffect(() => {
   //   dispatch(loadingRequestsActions.removeFromLoadRequest());
   // }, []);

   useEffect(() => {
      existingFormInputValuesObjRef.current = existingFormInputValuesObj;
   }, [existingFormInputValuesObj]);

   useEffect(() => {
      setElmPropertiesVariables({
         section,
         jsCheatSheetItemsObj,
         styles,
         parentMasterType,
         subListLevel,
         parentKey,
         parentsParentKey,
         parentMasterID,
         unlockProtectedVisible,
         onlyList,
         displayConditions,
         showProtectedHidden,
         refresh,
         allJSCheatSheetItems,
         emptyForm,
         setFormType,
         expandedItems,
         updateExistingFormState
      });
   }, [unlockProtectedVisible]);

   ////////////////////////////////////////////////////////////////////////
   /// HANDLERS
   ////////////////////////////////////////////////////////////////////////
   const unlockProtectedVisibleHandler = (e) => {
      e.preventDefault();
      const buttonMasterID = e.target.value;

      setUnlockProtectedVisible((prevState) => {
         const output = [...prevState];

         if (output.includes(buttonMasterID)) {
            output.splice(output.indexOf(buttonMasterID), 1);
            setRefresh(refresh++);
         } else {
            output.push(buttonMasterID);
         }

         return output;
      });
   };

   const openItemEditorButtonHandler = (e) => {
      e.preventDefault();
      const buttonMasterID = e.target.value;

      setItemEditorModalJSX(
         <ItemEditorModal
            id={buttonMasterID}
            user={user}
            refresh={new Date()}
         />
      );
   };

   const expandedItemsButtonHandler = (e) => {
      e.preventDefault();
      dispatch(
         jsCheatSheetDataActions.toggleExpandedItems({
            section,
            id: e.target.value
         })
      );
   };

   const showProtectedHiddenHandler = (e) => {
      e.preventDefault();

      const buttonMasterID = e.target.value;
      setShowProtectedHidden((prevState) => {
         const output = [...prevState];
         if (output.includes(buttonMasterID)) {
            output.splice(output.indexOf(buttonMasterID), 1);
         } else {
            output.push(buttonMasterID);
         }
         return output;
      });
   };

   const submitFormButtonHandler = (e) => {
      e.preventDefault();

      if (isDemo) {
         alert(isDemo);
         return;
      }

      dispatch(loadingRequestsActions.addToLoadRequest());

      // Allow a pause to ensure input data is fully updated to existing form state
      setTimeout(() => {
         const parentMasterID = e.target.getAttribute("data-parentmasterid");
         // const parentSection = e.target.getAttribute("data-section");
         const rawItemWithNewEdits = {
            ...jsCheatSheetItemsObj[parentMasterID]
         };
         // const id = rawItemWithNewEdits.id;
         const existingFormEdits = {
            ...formInputData.existingFormInputDataObj
         };

         for (const key in existingFormEdits[parentMasterID]) {
            // Convert to boolean.
            if (key === "markcomplete" || key === "markforreview") {
               const innerItem = existingFormEdits[parentMasterID][key];
               if (typeof innerItem === String) {
                  rawItemWithNewEdits[key] = ["true", "1", " "].includes(
                     innerItem.trim()
                  )
                     ? true
                     : false;
               } else {
                  rawItemWithNewEdits[key] = innerItem;
               }
            }

            if (
               existingFormEdits[parentMasterID][key] &&
               existingFormEdits[parentMasterID][key].constructor === Object
            ) {
               const newInnerItemWithNewEdits = {};

               for (const innerKey in existingFormEdits[parentMasterID][key]) {
                  newInnerItemWithNewEdits[innerKey] =
                     existingFormEdits[parentMasterID][key][innerKey];
               }

               if (key == 0) {
                  const newKey = Object.keys(newInnerItemWithNewEdits)[0];
                  rawItemWithNewEdits[newKey] =
                     newInnerItemWithNewEdits[newKey];
               } else {
                  rawItemWithNewEdits[key] = { ...newInnerItemWithNewEdits };
               }
            } else {
               rawItemWithNewEdits[key] =
                  existingFormEdits[parentMasterID][key];
            }
         }

         // Clean itemWithNewEdits
         const itemWithNewEdits = {};
         for (const [key, value] of Object.entries(rawItemWithNewEdits)) {
            if (key === "id") {
               delete itemWithNewEdits[key];
            } else if (
               (key === "markcomplete" || key === "markforreview") &&
               rawItemWithNewEdits[key].constructor !== Boolean
            ) {
               // Convert to boolean.
               const innerItem = Object.hasOwn(
                  existingFormEdits[parentMasterID],
                  key
               )
                  ? existingFormEdits[parentMasterID][key]
                  : false;

               if (typeof innerItem === String) {
                  itemWithNewEdits[key] =
                     innerItem.trim().toLowerCase === "true" ? true : false;
               } else {
                  itemWithNewEdits[key] = innerItem;
               }
            } else {
               itemWithNewEdits[key] = value;
            }
         }

         if (user) {
            // dispatch(
            //   jsCheatSheetDataActions.updateOneJSCheatSheetItem({
            //     id: id,
            //     item: itemWithNewEdits,
            //   }),
            // );

            dispatch(
               jsCheatSheetDataActions.updateJSCheatSheetDB({
                  itemWithNewEdits,
                  user
               })
            );
            // updateAJSCheatSheetItem(dataObj, user);
         } else {
            const sendEmail = window.confirm(
               'Thank you for contributing. All contributions must be reviewed before becoming public. Click "OK" to send this via email for review and, if approved, to be included. Click "Cancel" to cancel this and not send an email.'
            );
            if (sendEmail) {
               const questionAdminEmail = "general@glassinteractive.com";
               const subject = "An Edit Request for the Study Plan Tool";
               const title = itemWithNewEdits.title;
               const content = itemWithNewEdits.content;
               const slug = itemWithNewEdits.slug;
               const body = `Please consider this edit to the question titled ${title} (${slug})\n\n\nThe text with the new edits:\n\n___________________\n${content}`;
               window.open(
                  `mailto:${questionAdminEmail}?subject=${subject}l&body=${encodeURIComponent(
                     body
                  )}`
               );
            }
         }
         dispatch(loadingRequestsActions.removeFromLoadRequest());
      }, 1000);
   };

   const deleteFormButtonHandler = (e) => {
      e.preventDefault();
      dispatch(loadingRequestsActions.addToLoadRequest());

      if (isDemo) {
         alert(isDemo);
         return;
      }
      const parentMasterID = e.target.getAttribute("data-parentmasterid");
      const parentSection = e.target.getAttribute("data-section");
      const itemIdentifier = jsCheatSheetItemsObj[parentMasterID].identifier;
      const deleteItemFromDB =
         parentSection === "content" ? deleteContentDocFromDb : deleteDocFromDb;
      const confirm = window.confirm(
         "Are you sure you want to delete the " +
            jsCheatSheetItemsObj[parentMasterID].type +
            ' titled "' +
            jsCheatSheetItemsObj[parentMasterID].title +
            ' "?'
      );
      if (confirm && user) {
         deleteItemFromDB(itemIdentifier, user)
            .then((res) => {
               dispatch(loadingRequestsActions.removeFromLoadRequest());
               const status = res.status ? res.status : res.response.status;
               if (status >= 400) {
                  if (status === 403) {
                     dispatch(authActions.reLogin(true));
                  } else {
                     alert("There was an error: " + res.response.data.message);
                  }
               } else if (status >= 200) {
                  const resetPage = window.confirm(
                     'Success! The item has been deleted.\n\nWould you like to refresh the page and show the changes?\n\nNOTE: If you deleted this accidentally, you can choose "Cancel" below to return to the page with the deleted item still open. To re-add it back to your study plan, just click "Submit" at the bottom of the item. This will submit it as a new item to your study plan.'
                  );
                  if (resetPage)
                     dispatch(
                        jsCheatSheetDataActions.reGatherJSCheatSheet(true)
                     );
                  // setInEditMode(false);
               } else {
                  dispatch(loadingRequestsActions.removeFromLoadRequest());
                  alert("there was an error: " + res.message);
               }
            })
            .catch((err) => {
               dispatch(loadingRequestsActions.removeFromLoadRequest());
               alert("It appears we encountered a problem: " + err);
            });
      } else if (confirm) {
         dispatch(loadingRequestsActions.removeFromLoadRequest());
         const sendEmail = window.confirm(
            'Thank you for contributing. All contributions must be reviewed before becoming public. Click "OK" to send this via email for review and, if approved, to be included. Click "Cancel" to cancel this and not send an email.'
         );
         if (sendEmail) {
            const questionAdminEmail = "general@glassinteractive.com";
            const subject = "An Edit Request for the Study Plan Tool";
            const body = `A question edit is being recommended: ${JSON
               .stringify
               // editedQuestions.current.edits
               ()}`;
            window.open(
               `mailto:${questionAdminEmail}?subject=${subject}l&body=${encodeURIComponent(
                  body
               )}`
            );
         }
      } else {
         dispatch(loadingRequestsActions.removeFromLoadRequest());
      }
   };

   ////////////////////////////////////////////////////////////////////////
   /// OUTPUT
   ////////////////////////////////////////////////////////////////////////

   // if (jsCheatSheetItemsObj) console.log("-->", jsCheatSheetItemsObj);

   if (jsCheatSheetItemsObj)
      return (
         <Fragment>
            {itemEditorModalJSX && itemEditorModalJSX}
            {sortOrder.map((key) => {
               let isInFilter = true;

               if (currentFilters && currentFilters.length > 0) {
                  isInFilter = jsCheatSheetItemsObj[key].tag_names.some(
                     (tagName) => {
                        return currentFilters.includes(tagName);
                     }
                  );
               }

               if (!isInFilter) return;

               /////////////////////////////////////////
               // Study Plan Items Sub-List (if needed)
               /////////////////////////////////////////

               // if (
               //   1 === 2 &&
               //   jsCheatSheetItemsObj[key] &&
               //   typeof jsCheatSheetItemsObj[key] === "object"
               // )
               //   return (
               //     <ul
               //       data-marker="CATALOG-ITEM-LIST-2"
               //       type={props.type ? props.type : jsCheatSheetItemsObj[key].type}
               //       data-parentmastertype={
               //         parentMasterType
               //           ? parentMasterType
               //           : jsCheatSheetItemsObj[key].type
               //       }
               //       {...elmProperties({
               //         ...elmPropertiesVariables,
               //         key,
               //       }).ul1}
               //     >
               //
               //       <Fragment key={key + section}>
               //         <PushButton
               //           key={key + "sub-goals-as-list"}
               //           inputOrButton="button"
               //           id={"create-entry-btn" + key}
               //           colorType="primary"
               //           styles={{
               //             ...elmProperties({
               //               ...elmPropertiesVariables,
               //               key,
               //             }).pushButton2,
               //           }}
               //           value={
               //             key === "0" &&
               //             Object.hasOwn(jsCheatSheetItemsObj[key], "id")
               //               ? jsCheatSheetItemsObj[key]["id"]
               //               : key
               //           }
               //           parentmasterid={key}
               //           data=""
               //           size="small"
               //           onClick={expandedItemsButtonHandler}
               //         >
               //           {(!Object.hasOwn(expandedItems, section) ||
               //             !expandedItems[section].includes(key)) && (
               //             <Fragment>
               //               <Fragment>&darr; More &darr;</Fragment>
               //             </Fragment>
               //           )}
               //           {Object.hasOwn(expandedItems, section) &&
               //             expandedItems[section].includes(key) && (
               //               <Fragment>&darr; Less &darr;</Fragment>
               //             )}
               //         </PushButton>
               //       </Fragment>

               //       <Fragment
               //         key={
               //           " flow - 2 -" +
               //           Object.hasOwn(jsCheatSheetItemsObj[key], "id")
               //             ? jsCheatSheetItemsObj[key]["id"]
               //             : key
               //         }
               //       >
               //         <div
               //           key={key + "-collapsible-elm"}
               //           id={key + "-collapsible-elm"}
               //           style={{
               //             position: "relative",
               //             maxWidth: "100%",
               //           }}
               //           className={
               //             styles["pseudo-collapsible-elm"] +
               //             " " +
               //             (Object.hasOwn(expandedItems, section) &&
               //               expandedItems[section].includes(
               //                 key === "0" &&
               //                   Object.hasOwn(jsCheatSheetItemsObj[key], "id")
               //                   ? jsCheatSheetItemsObj[key]["id"]
               //                   : key,
               //               ) &&
               //               styles["collapsible-elm-open"])
               //           }
               //           data-container-type="collapsibleElm"
               //         >
               //           {/* Item Group Title*/}

               //           <h2
               //             className={
               //               styles["group-title"] +
               //               " " +
               //               styles[parentKey] +
               //               " " +
               //               styles.title
               //             }
               //           >
               //             {jsCheatSheetItemsObj[key] &&
               //             Object.hasOwn(jsCheatSheetItemsObj[key], "title") ? (
               //               <Fragment>
               //                 <span className={styles["title"]}>
               //                   {jsCheatSheetItemsObj[key].title}
               //                 </span>
               //               </Fragment>
               //             ) : (
               //               key
               //             )}
               //           </h2>

               //           <JSCheatSheetItemsSubList
               //             key={jsCheatSheetItemsObj[key]}
               //             jsCheatSheetItemsObj={jsCheatSheetItemsObj[key]}
               //             allJSCheatSheetItems={props.allJSCheatSheetItems}
               //             parentKey={key}
               //             parentsParentKey={parentKey}
               //             parentMasterID={
               //               parentMasterID
               //                 ? parentMasterID
               //                 : jsCheatSheetItemsObj[key].id
               //             }
               //             section={section}
               //             displayConditions={displayConditions}
               //             subListLevel={subListLevel}
               //             unlockProtectedVisible={
               //               props.unlockProtectedVisible
               //                 ? props.unlockProtectedVisible
               //                 : unlockProtectedVisible
               //             }
               //             showProtectedHidden={
               //               props.showProtectedHidden
               //                 ? props.showProtectedHidden
               //                 : showProtectedHidden
               //             }
               //             refresh={refresh}
               //             onlyList={onlyList}
               //             emptyForm={props.emptyForm}
               //             setFormType={props.setFormType}
               //           />

               //           {
               //             /////////////////////////////////////////
               //             // Item Group Edit, Show/Hide,
               //             // Delete and Submit Buttons
               //             /////////////////////////////////////////
               //           }
               //           {!onlyList && !subListLevel && (
               //             <div className={styles["button-container"]}>
               //               {!noEditButton && (
               //                 <button
               //                   className={
               //                     styles["form-button"] +
               //                     " " +
               //                     styles["edit-form-button"]
               //                   }
               //                   value={
               //                     key != 0 ? key : jsCheatSheetItemsObj[key].id
               //                   }
               //                   data-parentmasterid={
               //                     key !== 0 ? key : jsCheatSheetItemsObj[key].id
               //                   }
               //                   onClick={unlockProtectedVisibleHandler}
               //                 >
               //                   {!unlockProtectedVisible.includes(key) && (
               //                     <Fragment>
               //                       {" "}
               //                       <span className={styles["edit-button-title"]}>
               //                         <span>Edit </span>
               //                       </span>
               //                       <span
               //                         className={styles["edit-button-target-title"]}
               //                       >
               //                         "{jsCheatSheetItemsObj[key].title}"
               //                       </span>
               //                     </Fragment>
               //                   )}
               //                   {unlockProtectedVisible.includes(key) && (
               //                     <Fragment>
               //                       {" "}
               //                       <span
               //                         className={styles["edit-button-cancel-title"]}
               //                       >
               //                         Cancel Editor
               //                       </span>
               //                     </Fragment>
               //                   )}
               //                 </button>
               //               )}{" "}
               //               <button
               //                 className={
               //                   styles["form-button"] +
               //                   " " +
               //                   styles["show-hidden-form-button"]
               //                 }
               //                 value={key}
               //                 data-parentmasterid={key}
               //                 onClick={showProtectedHiddenHandler}
               //               >
               //                 Show Hidden Fields
               //               </button>
               //               {!onlyList && unlockProtectedVisible.includes(key) && (
               //                 <Fragment>
               //                   <button
               //                     className={
               //                       styles["form-button"] +
               //                       " " +
               //                       styles["submit-form-button"]
               //                     }
               //                     value={key}
               //                     data-parentmasterid={key}
               //                     data-section={section}
               //                     onClick={submitFormButtonHandler}
               //                   >
               //                     Submit Changes
               //                   </button>{" "}
               //                   <button
               //                     className={
               //                       styles["form-button"] +
               //                       " " +
               //                       styles["delete-form-button"]
               //                     }
               //                     value={key}
               //                     data-parentmasterid={key}
               //                     data-section={section}
               //                     onClick={deleteFormButtonHandler}
               //                   >
               //                     Delete
               //                   </button>
               //                 </Fragment>
               //               )}
               //             </div>
               //           )}

               //           {
               //             /////////////////////////////////////////
               //             // Sub-List (Study Plan item) Edit,
               //             // Show/Hide, Delete and Submit Buttons.
               //             /////////////////////////////////////////
               //           }

               //           {subListLevel && (
               //             <div className={styles["button-container"]}>
               //               {!noEditButton && (
               //                 <button
               //                   className={
               //                     styles["form-button"] +
               //                     " " +
               //                     styles["edit-form-button"]
               //                   }
               //                   value={
               //                     key != 0 ? key : jsCheatSheetItemsObj[key].id
               //                   }
               //                   data-parentmasterid={
               //                     key != 0 ? key : jsCheatSheetItemsObj[key].id
               //                   }
               //                   onClick={openItemEditorButtonHandler}
               //                 >
               //                   <Fragment>
               //                     <span>Edit </span>
               //                     <span
               //                       className={styles["edit-button-target-title"]}
               //                     >
               //                       "{jsCheatSheetItemsObj[key].title}"
               //                     </span>
               //                   </Fragment>
               //                 </button>
               //               )}
               //               <button
               //                 className={
               //                   styles["form-button"] +
               //                   " " +
               //                   styles["show-hidden-form-button"]
               //                 }
               //                 value={key}
               //                 data-parentmasterid={key}
               //                 onClick={showProtectedHiddenHandler}
               //               >
               //                 Show Hidden Fields
               //               </button>
               //               {!onlyList && unlockProtectedVisible.includes(key) && (
               //                 <Fragment>
               //                   <button
               //                     className={
               //                       styles["form-button"] +
               //                       " " +
               //                       styles["submit-form-button"]
               //                     }
               //                     value={key}
               //                     data-parentmasterid={key}
               //                     data-section={section}
               //                     onClick={submitFormButtonHandler}
               //                   >
               //                     Submit Changes
               //                   </button>{" "}
               //                   <button
               //                     className={
               //                       styles["form-button"] +
               //                       " " +
               //                       styles["delete-form-button"]
               //                     }
               //                     value={key}
               //                     data-parentmasterid={key}
               //                     data-section={section}
               //                     onClick={deleteFormButtonHandler}
               //                   >
               //                     Delete
               //                   </button>
               //                 </Fragment>
               //               )}
               //             </div>
               //           )}
               //         </div>
               //       </Fragment>
               //     </ul>
               //   );

               /////////////////////////////////////////
               // If No Sub-List is Needed, Create Item
               /////////////////////////////////////////
               return (
                  <Fragment key={key}>
                     <article
                        key={key + jsCheatSheetItemsObj[key].title}
                        id={jsCheatSheetItemsObj[key].title}
                        className={
                           styles["parent-article"] +
                           " grid-item category-javascript " +
                           "" +
                           "category-" +
                           jsCheatSheetItemsObj[key].categories
                              .toString()
                              .replaceAll(",", " category-") +
                           " " +
                           "tag-" +
                           jsCheatSheetItemsObj[key].tag_names
                              .toString()
                              .replaceAll(",", " tag-") +
                           " " +
                           " " +
                           (!Object.hasOwn(expandedItems, section) ||
                           !expandedItems[section].includes(key)
                              ? styles.closed
                              : styles.open)
                        }
                     >
                        <h2
                           className={styles["article-title"]}
                           dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                 jsCheatSheetItemsObj[key].title
                              )
                           }}
                        />

                        <PushButton
                           key={key + "sub-goals-as-list"}
                           inputOrButton="button"
                           id={"create-entry-btn" + key}
                           colorType="primary"
                           styles={{
                              ...elmProperties({
                                 ...elmPropertiesVariables,
                                 key
                              }).pushButton2
                           }}
                           value={
                              key === "0" &&
                              Object.hasOwn(jsCheatSheetItemsObj[key], "id")
                                 ? jsCheatSheetItemsObj[key]["id"]
                                 : key
                           }
                           parentmasterid={key}
                           data=""
                           size="small"
                           onClick={expandedItemsButtonHandler}
                        >
                           {(!Object.hasOwn(expandedItems, section) ||
                              (Object.hasOwn(expandedItems, section) &&
                                 !expandedItems[section].includes(key))) && (
                              <Fragment>
                                 <Fragment>&darr; More &darr;</Fragment>
                              </Fragment>
                           )}
                           {Object.hasOwn(expandedItems, section) &&
                              expandedItems[section].includes(key) && (
                                 <Fragment>&darr; Less &darr;</Fragment>
                              )}
                        </PushButton>
                        <div
                           key={
                              "content" + key + jsCheatSheetItemsObj[key].title
                           }
                           id={jsCheatSheetItemsObj[key].title}
                           className={styles["article-content"]}
                        >
                           {Object.hasOwn(expandedItems, section) &&
                              expandedItems[section].includes(key) &&
                              Object.entries(jsCheatSheetItemsObj[key]).map(
                                 (data) => {
                                    const type = data[0];
                                    let value = data[1];

                                    if (typeof value !== "string")
                                       value = value.toString();
                                    // return (
                                    //   <JSCheatSheetItem
                                    //     key={
                                    //       parentMasterID + parentsParentKey + parentKey + key
                                    //     }
                                    //     jsCheatSheetItemsObj={value}
                                    //     passedKey={type}
                                    //     unlockProtectedVisible={
                                    //       props.unlockProtectedVisible
                                    //         ? props.unlockProtectedVisible
                                    //         : unlockProtectedVisible
                                    //     }
                                    //     showProtectedHidden={
                                    //       props.showProtectedHidden
                                    //         ? props.showProtectedHidden
                                    //         : showProtectedHidden
                                    //     }
                                    //     parentKey={key}
                                    //     parentsParentKey={parentsParentKey}
                                    //     parentMasterID={parentMasterID}
                                    //     parentMasterType={
                                    //       parentMasterType
                                    //         ? parentMasterType
                                    //         : jsCheatSheetItemsObj[key]
                                    //           ? jsCheatSheetItemsObj[key].type
                                    //           : ""
                                    //     }
                                    //     displayConditions={displayConditions}
                                    //     refresh={refresh}
                                    //   />
                                    // );

                                    return (
                                       <p
                                          key={type}
                                          className={
                                             styles[type] +
                                             " " +
                                             type +
                                             " " +
                                             styles[
                                                "protectedHidden-" +
                                                   (displayConditions &&
                                                      Object.hasOwn(
                                                         displayConditions,
                                                         "protectedHidden"
                                                      ) &&
                                                      displayConditions.protectedHidden.includes(
                                                         type
                                                      ) &&
                                                      !showProtectedHidden.includes(
                                                         key
                                                      ))
                                             ] +
                                             " " +
                                             styles[
                                                "protectedVisible-" +
                                                   (displayConditions.protectedVisible.includes(
                                                      "PROTECT-ALL"
                                                   ) &&
                                                      !unlockProtectedVisible.includes(
                                                         key
                                                      )) ||
                                                   (displayConditions.protectedVisible.includes(
                                                      key
                                                   ) &&
                                                      !unlockProtectedVisible.includes(
                                                         key
                                                      ))
                                             ] +
                                             " " +
                                             styles[parentKey] +
                                             " " +
                                             styles[parentMasterType] +
                                             " " +
                                             styles[parentKey + "-" + key] +
                                             " " +
                                             styles[key]
                                          }
                                       >
                                          {refresh && (
                                             <JSCheatSheetItem
                                                key={
                                                   parentMasterID +
                                                   parentsParentKey +
                                                   parentKey +
                                                   key
                                                }
                                                jsCheatSheetItemsObj={DOMPurify.sanitize(
                                                   value
                                                )}
                                                setDangerousHTML={true}
                                                passedKey={type}
                                                unlockProtectedVisible={
                                                   props.unlockProtectedVisible
                                                      ? props.unlockProtectedVisible
                                                      : unlockProtectedVisible
                                                }
                                                showProtectedHidden={
                                                   props.showProtectedHidden
                                                      ? props.showProtectedHidden
                                                      : showProtectedHidden
                                                }
                                                parentKey={key}
                                                parentsParentKey={
                                                   parentsParentKey
                                                }
                                                parentMasterID={
                                                   parentMasterID
                                                      ? parentMasterID
                                                      : key
                                                }
                                                parentMasterType={
                                                   parentMasterType
                                                      ? parentMasterType
                                                      : jsCheatSheetItemsObj[
                                                             key
                                                          ]
                                                        ? jsCheatSheetItemsObj[
                                                             key
                                                          ].type
                                                        : ""
                                                }
                                                section={section}
                                                displayConditions={
                                                   displayConditions
                                                }
                                                refresh={refresh}
                                             />
                                          )}
                                       </p>
                                    );
                                 }
                              )}
                        </div>
                        {
                           /////////////////////////////////////////
                           // Item Group Edit, Show/Hide,
                           // Delete and Submit Buttons
                           /////////////////////////////////////////
                        }
                        {!onlyList && !subListLevel && (
                           <div className={styles["button-container"]}>
                              {!noEditButton && (
                                 <button
                                    className={
                                       styles["form-button"] +
                                       " " +
                                       styles["edit-form-button"]
                                    }
                                    value={
                                       key != 0
                                          ? key
                                          : jsCheatSheetItemsObj[key].id
                                    }
                                    data-parentmasterid={
                                       key !== 0
                                          ? key
                                          : jsCheatSheetItemsObj[key].id
                                    }
                                    onClick={unlockProtectedVisibleHandler}
                                 >
                                    {!unlockProtectedVisible.includes(key) && (
                                       <Fragment>
                                          {" "}
                                          <span
                                             className={
                                                styles["edit-button-title"]
                                             }
                                          >
                                             <span>Edit </span>
                                          </span>
                                          <span
                                             className={
                                                styles[
                                                   "edit-button-target-title"
                                                ]
                                             }
                                          >
                                             "{jsCheatSheetItemsObj[key].title}"
                                          </span>
                                       </Fragment>
                                    )}
                                    {unlockProtectedVisible.includes(key) && (
                                       <Fragment>
                                          {" "}
                                          <span
                                             className={
                                                styles[
                                                   "edit-button-cancel-title"
                                                ]
                                             }
                                          >
                                             Cancel Editor
                                          </span>
                                       </Fragment>
                                    )}
                                 </button>
                              )}{" "}
                              <button
                                 className={
                                    styles["form-button"] +
                                    " " +
                                    styles["show-hidden-form-button"]
                                 }
                                 value={key}
                                 data-parentmasterid={key}
                                 onClick={showProtectedHiddenHandler}
                              >
                                 Show Hidden Fields
                              </button>
                              {!onlyList &&
                                 unlockProtectedVisible.includes(key) && (
                                    <Fragment>
                                       <button
                                          className={
                                             styles["form-button"] +
                                             " " +
                                             styles["submit-form-button"]
                                          }
                                          value={key}
                                          data-parentmasterid={key}
                                          data-section={section}
                                          onClick={submitFormButtonHandler}
                                       >
                                          Submit Changes
                                       </button>{" "}
                                       <button
                                          className={
                                             styles["form-button"] +
                                             " " +
                                             styles["delete-form-button"]
                                          }
                                          value={key}
                                          data-parentmasterid={key}
                                          data-section={section}
                                          onClick={deleteFormButtonHandler}
                                       >
                                          Delete
                                       </button>
                                    </Fragment>
                                 )}
                           </div>
                        )}

                        {
                           /////////////////////////////////////////
                           // Sub-List (Study Plan item) Edit,
                           // Show/Hide, Delete and Submit Buttons.
                           /////////////////////////////////////////
                        }

                        {subListLevel && (
                           <div className={styles["button-container"]}>
                              {!noEditButton && (
                                 <button
                                    className={
                                       styles["form-button"] +
                                       " " +
                                       styles["edit-form-button"]
                                    }
                                    value={
                                       key != 0
                                          ? key
                                          : jsCheatSheetItemsObj[key].id
                                    }
                                    data-parentmasterid={
                                       key != 0
                                          ? key
                                          : jsCheatSheetItemsObj[key].id
                                    }
                                    onClick={openItemEditorButtonHandler}
                                 >
                                    <Fragment>
                                       <span>Edit </span>
                                       <span
                                          className={
                                             styles["edit-button-target-title"]
                                          }
                                       >
                                          "{jsCheatSheetItemsObj[key].title}"
                                       </span>
                                    </Fragment>
                                 </button>
                              )}
                              <button
                                 className={
                                    styles["form-button"] +
                                    " " +
                                    styles["show-hidden-form-button"]
                                 }
                                 value={key}
                                 data-parentmasterid={key}
                                 onClick={showProtectedHiddenHandler}
                              >
                                 Show Hidden Fields
                              </button>
                              {!onlyList &&
                                 unlockProtectedVisible.includes(key) && (
                                    <Fragment>
                                       <button
                                          className={
                                             styles["form-button"] +
                                             " " +
                                             styles["submit-form-button"]
                                          }
                                          value={key}
                                          data-parentmasterid={key}
                                          data-section={section}
                                          onClick={submitFormButtonHandler}
                                       >
                                          Submit Changes
                                       </button>{" "}
                                       <button
                                          className={
                                             styles["form-button"] +
                                             " " +
                                             styles["delete-form-button"]
                                          }
                                          value={key}
                                          data-parentmasterid={key}
                                          data-section={section}
                                          onClick={deleteFormButtonHandler}
                                       >
                                          Delete
                                       </button>
                                    </Fragment>
                                 )}
                           </div>
                        )}
                     </article>
                  </Fragment>
               );
            })}
         </Fragment>
      );
   return (
      <div>
         <h2>There are no items to list.</h2>
      </div>
   );
};

export default JSCheatSheetItemsList;
