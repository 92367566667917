import useSortList from "./useSortList";
import useAssembleJSCheatSheetList from "./useAssembleJSCheatSheetList";
import { useSelector } from "react-redux";

const useInitJSCheatSheetItems = () => {
  const jsCheatSheetItemSchema = useSelector(
    (state) => state.jsCheatSheetData.schema,
  );
  const sortList = useSortList();
  const assembleJSCheatSheetList = useAssembleJSCheatSheetList();
  const outputFunction = async (props) => {
    const { typeArray, sortMethod, dataObjForEdit, allJSCheatSheetItems } =
      props;

    let schema = jsCheatSheetItemSchema;
    // if (id === "content") {
    //   schema = getSchemaForContentItem;
    // }
    if (!schema) return false;
    const processDataWithSchema = (schema) => {
      // const groomedSchema = {}
      // // Organize Schema
      // orderOfOutputArray.forEach(topic=>{
      //   groomedSchema[topic] = data.tree[topic]
      // })

      // Gather items to list based on typeArray.
      const { groomedOutput, groomedAllItemOutput } = assembleJSCheatSheetList({
        typeArray,
        keysToUseArray: [...Object.keys(schema), "dependencies"],
        dataObjForEdit,
        allJSCheatSheetItems,
      });

      ////////////////////////////////////////////////////////////////////////
      /// Sort groomedOutput
      ////////////////////////////////////////////////////////////////////////
      const sortBy = Object.keys(schema).includes(
        sortMethod.replace("-reverse", ""),
      )
        ? sortMethod
        : "slug";

      const sortedIDArray = sortList({
        sortMethod: sortBy,
        objectToBeSorted: groomedOutput,
      });

      return { groomedAllItemOutput, sortedIDArray };
    };

    return processDataWithSchema(schema);
  };
  return outputFunction;
};

export default useInitJSCheatSheetItems;
