import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Styles from "./WordSearchTool.module.scss";
import { jsCheatSheetDataActions } from "../../store/jsCheatSheetDataSlice";
import PushButton from "../../UI/Buttons/PushButton/PushButton.js";

const WordSearchTool = () => {
   const dispatch = useDispatch();
   const [inputValue, setInputValue] = useState();
   const { jsCheatSheetMetadata, inSearchMode, learnModeOn, expandedItems } =
      useSelector((state) => state.jsCheatSheetData);

   ///////////////////////////////////////////////////////////////////////
   // HANDLERS
   ///////////////////////////////////////////////////////////////////////
   const searchWordInputHandler = (e) => {
      if (!inSearchMode) {
         dispatch(jsCheatSheetDataActions.setInSearchMode(true));
         dispatch(
            jsCheatSheetDataActions.expandAllItems({
               section: "jsCheatSheet-main",
               idArray: jsCheatSheetMetadata.id
            })
         );
      }

      setInputValue(e.target.value);
   };

   ///////////////////////////////////////////////////////////////////////
   // Effects
   ///////////////////////////////////////////////////////////////////////
   useEffect(() => {
      if (inSearchMode && !inputValue) {
         dispatch(jsCheatSheetDataActions.setInSearchMode(false));
      }

      const delayDebounceFn = setTimeout(() => {
         var myHilitor = new Hilitor("jsCheatSheet-main-collapsible-elm"); // id of the element to parse
         myHilitor.setMatchType("left");
         myHilitor.apply(inputValue);
         addDataToMark();
      }, 700);

      return () => clearTimeout(delayDebounceFn);
   }, [inputValue, learnModeOn, expandedItems]);

   ////////////////////////////////////////////////////////////////
   // Output
   ////////////////////////////////////////////////////////////////
   return (
      <div
         id="word-search-buttons-container"
         className={Styles["word-search-container"]}
      >
         <PushButton
            inputOrButton="button"
            id="search-previous-btn"
            colorType="primary"
            value="previous"
            data-value="previous"
            size="small"
            data-dir="-1"
            styles={{
               color: "var(--jscs-color-accent)",
               background: "var(--jscs-color-background)",
               margin: "auto",
               minWidth: "min-content",
               font: "var(--jscs--font-heading-2)",
               fontVariant: "small-caps",
               fontSize: "1.4rem",
               padding: "0.25em 0.5em",
               borderRadius: "50% 0 0 50%"
            }}
            classes="hidden-button"
            onClick={nextPrevBtnsHandler}
         >
            &larr;
         </PushButton>
         <input
            type="text"
            // defaultValue={searchWords}
            onChange={searchWordInputHandler}
            className={Styles["search-terms-box"]}
            placeholder="Search"
         />

         <PushButton
            inputOrButton="button"
            id="search-next-btn"
            colorType="primary"
            value="next"
            data-value="next"
            size="small"
            data-dir="1"
            styles={{
               color: "var(--jscs-color-accent)",
               background: "var(--jscs-color-background)",
               margin: "auto",
               minWidth: "min-content",
               font: "var(--jscs--font-heading-2)",
               fontVariant: "small-caps",
               fontSize: "1.4rem",
               padding: "0.25em 0.5em",
               borderRadius: " 0 50% 50% 0"
            }}
            classes="hidden-button"
            onClick={nextPrevBtnsHandler}
         >
            &rarr;
         </PushButton>
         <div id="jump-box-wrap" className={Styles["jump-box-wrap"]}>
            <input
               id="jump-to-options"
               type="text"
               // defaultValue={searchWords}
               // onChange={searchWordInputHandler}
               placeholder=""
               className={Styles["jump-to-box"]}
            />

            <span
               id="jump-box-total-amount"
               className={Styles["jump-box-total-amount"]}
            ></span>
         </div>
      </div>
   );
};

export default WordSearchTool;

////////////////////////////////////////////////////////////////
// Search Nav and Jump Buttons
////////////////////////////////////////////////////////////////
/////
//////////  Find the Position
// function getPosition(element) {
//    var xPosition = 0;
//    var yPosition = 0;

//    while (element) {
//       xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
//       yPosition += element.offsetTop - element.scrollTop + element.clientTop;
//       element = element.offsetParent;
//    }
//    //   console.log('[xPosition, yPosition]', [xPosition, yPosition])
//    return [xPosition, yPosition];
// }

/////
//////////  Elm Has a Parent with Class
// function hasSomeParentTheClass(element, classname) {
//    if (!element || element.tagName === undefined) return;

//    if (element.className.split(" ").indexOf(classname) >= 0) return true;

//    return (
//       element.parentNode && hasSomeParentTheClass(element.parentNode, classname)
//    );
// }

/////
//////////  Find all <mark>'s
const amountOfMarks = function () {
   const allMarks = document.querySelectorAll("mark");

   // allMarks.forEach((markElm, i) => {
   //   markElm.classList.add("mark-" + (i + 1));
   // });
   //  const theScrollPoints = [];

   //  allMarks.forEach((mark) => {
   // const markParent = mark.parentNode;

   //     if (!hasSomeParentTheClass(mark, "cheatsheet-mode")) {
   //        const theMarkGroup = getPosition(mark);
   //        theScrollPoints.push(theMarkGroup);
   //     }
   //  });
   //  if (!returnOnly) jumpToBox(theScrollPoints);

   return allMarks.length;
};

/////
//////////  Adding Class & ID to <mark>
function addDataToMark(moveToStart) {
   // Where el is the DOM element you'd like to test for visibility
   //  function isHidden(el) {
   //     var style = window.getComputedStyle(el);
   //     const parentStyle = window.getComputedStyle(el.parentElement);
   //     const parentParentStyle = window.getComputedStyle(
   //        el.parentElement.parentElement
   //     );
   //     return (
   //        style.display === "none" ||
   //        parentStyle.display === "none" ||
   //        parentParentStyle.display === "none"
   //     );
   //  }
   function isHidden(elm) {
      const elmLearnModeParent = elm.closest('[class*="learn-mode"');

      const elmParentIsHidden = elmLearnModeParent
         ? window.getComputedStyle(elmLearnModeParent).display === "none"
         : false;
      const elmIsProtectedHidden =
         elm.parentNode.getAttribute("data-protected-hidden") === "true"
            ? true
            : false;

      return elmParentIsHidden || elmIsProtectedHidden;
   }

   function notInExcerpt(elm) {
      return !elm.closest('[data-category="excerpt"');
   }
   const totalHitsElm = document.getElementById("jump-box-total-amount");
   const currentSelectionElm = document.getElementById("jump-to-options");
   const hitsBoxWrap = document.getElementById("jump-box-wrap");
   const buttonPrev = document.getElementById("search-previous-btn");

   const buttonNext = document.getElementById("search-next-btn");

   const allMarks = document.querySelectorAll("mark");

   if (allMarks.length > 0) {
      let cnt = 1;
      let removedElmCounts = 0;

      allMarks.forEach((markElm) => {
         if (!isHidden(markElm) && notInExcerpt(markElm)) {
            if (!markElm.classList.contains("mark-"))
               markElm.classList.add("mark-" + cnt);
            if (!markElm.id.includes("mark-")) markElm.id = "mark-" + cnt;
            cnt++;
         } else {
            const parent = markElm.parentNode;
            parent.replaceChild(markElm.firstChild, markElm);
            parent.normalize();
            removedElmCounts++;
         }
      });
      totalHitsElm.innerText =
         "/" + (allMarks.length - removedElmCounts) + "hits";
      buttonPrev.classList.remove("hidden-button");
      buttonNext.classList.remove("hidden-button");
      hitsBoxWrap.classList.remove("hidden-button");
      if (moveToStart) scrollToTheMark(allMarks.length, 1, 1);
   } else {
      totalHitsElm.innerText = "";
      currentSelectionElm.value = "";
      buttonPrev.classList.add("hidden-button");
      buttonNext.classList.add("hidden-button");
      hitsBoxWrap.classList.add("hidden-button");
   }
}

/////
//////////  Go to Jump Box
// const jumpToBox = function (theScrollPoints) {
//    //	Create the jump box
//    const jumpBox = document.createElement("FORM");
//    jumpBox.id = "jump-to-form-wrap";
//    jumpBox.className = "form jump-to-form";

//    //	Create the SELECT input
//    const selectInput = document.createElement("SELECT");
//    selectInput.id = "jump-to-options";
//    selectInput.name = "jump-to-options";
//    selectInput.className = "input jump-to-options select";

//    //	Create the options
//    let pointNumber = 0;
//    theScrollPoints.forEach(() => {
//       const option = document.createElement("OPTION");
//       pointNumber++;
//       option.value = pointNumber;
//       option.innerText = pointNumber;
//       selectInput.append(option);
//    });

//    //	Put it all together
//    jumpBox.append(selectInput);
//    const jumboxWrap = document.getElementById("jumpbox-wrap");
//    jumboxWrap.innerHTML = "";
//    jumboxWrap.append(jumpBox);
//    // const optionsForm = document.getElementById("jump-to-form-wrap");
//    const optionSelection = document.getElementById("jump-to-options");
//    optionSelection.addEventListener("change", function (e) {
//       e.preventDefault();
//       scrollToTheMark(theScrollPoints);
//    });

//    //	Create the target cursor
//    const checkTargetCursorExists = document.getElementById("target-cursor");
//    if (!checkTargetCursorExists) {
//       const targetCursorElm = document.createElement("DIV");
//       targetCursorElm.id = "target-cursor";
//       targetCursorElm.className = "target-cursor";
//       targetCursorElm.style.position = "absolute";
//       document.body.append(targetCursorElm);
//    }
// };

/////
//////////  Find the Next Mark
const findNextVisibleMark = (selector, numberModifier, direction, lastMark) => {
   if (numberModifier === 0) {
      const directionIsreverse = direction < 0;
      if (directionIsreverse) {
         numberModifier = lastMark;
      } else {
         numberModifier = 3;
      }
   }

   const element = document.getElementById(selector + numberModifier);

   if (!element) {
      console.log("NO ELM");
      return document.getElementById(selector + 1);
   }

   function isElementVisible(el) {
      return !!el.offsetParent;
   }

   if (!isElementVisible(element)) {
      return findNextVisibleMark(
         selector,
         numberModifier + direction,
         direction,
         lastMark
      );
   }

   return { element, currentNumber: numberModifier };
};

/////
//////////  Scrolling to the Mark
const scrollToTheMark = function (lastMark, forcedSelection, direction) {
   const selectionElm = document.getElementById("jump-to-options");
   if (forcedSelection > lastMark) forcedSelection = 1; // wrap around to start

   if (forcedSelection) selectionElm.value = forcedSelection;
   let selection = selectionElm.value ? selectionElm.value * 1 : 1;

   if (selection) {
      // var scrollOptions = {
      //   left: mark[0],
      //   top: mark[1] - 400,
      //   behavior: "smooth",
      // };
      const prevElement = document.getElementById(
         "mark-" + (selection - direction)
      );
      if (prevElement) prevElement.classList.remove("search-target-mark");

      const { element, currentNumber } = findNextVisibleMark(
         "mark-",
         selection,
         direction,
         lastMark
      );

      if (element) {
         element.classList.add("search-target-mark");
         document.getElementById("jump-to-options").value = currentNumber;
         element?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center"
         });
      } else {
         let targetElmObj = null;

         /* No elm found, so return to beginning.
          This creates a forward wrap-around search.
          If "selection" is reduced to 0, reverse 
          wrap-around. Otherwise, reverse wrap-around 
          is handled in nextPrevBtnsHandler.
         */

         switch (direction) {
            case direction < 0:
               targetElmObj = findNextVisibleMark(
                  "mark-",
                  lastMark,
                  direction,
                  lastMark
               );
               break;
            default:
               targetElmObj = findNextVisibleMark(
                  "mark-",
                  1,
                  direction,
                  lastMark
               );
         }
         const targetElm = targetElmObj.element;

         if (targetElm) {
            targetElm?.classList.add("search-target-mark");
            targetElm.scrollIntoView({
               behavior: "smooth",
               block: "center",
               inline: "center"
            });
         } else {
            alert("There do not appear to be any hits for this search term.");
         }
      }
   } else {
      console.log("no selection made");
   }
};

/////
//////////  Next & Previous Button Handler
function nextPrevBtnsHandler(e) {
   e.preventDefault();
   const marksLimit = amountOfMarks();

   // Get the current number
   const btnDirection = e.target.dataset.dir * 1;

   // Activate the jump
   const currentSelection =
      document.getElementById("jump-to-options").value * 1;

   // const currentSelection = 1;
   let movement = currentSelection + btnDirection;

   // This creates reverse wrap-around search
   // Forward wrap-around is handled at the
   // point of search in scrollToTheMark
   if (movement === 0) movement = marksLimit;

   scrollToTheMark(marksLimit, movement, btnDirection);
}

/////
//////////  Set Jump Box Listeners for Navigation
// const setJumpBoxNavBtnsListeners = function () {
//   const navBtns = document.querySelectorAll(".jumbox-nav-btn");
//   navBtns.forEach((btn) => {
//     btn.addEventListener("click", nextPrevBtnsHandler);
//   });
// };
// setJumpBoxNavBtnsListeners();

///////////////////////////////////////////////////////////////////////
// hilitor.js
///////////////////////////////////////////////////////////////////////
function Hilitor(id, tag) {
   // Original JavaScript code by Chirp Internet: www.chirpinternet.eu
   // Please acknowledge use of this code by including this header.

   // private variables
   var targetNode = document.getElementById(id) || document.body;
   var hiliteTag = tag || "MARK";
   var skipTags = new RegExp("^(?:" + hiliteTag + "|SCRIPT|FORM|SPAN)$");
   var colors = ["#ff6", "#a0ffff", "#9f9", "#f99", "#f6f"];
   var wordColor = [];
   var colorIdx = 0;
   var matchRegExp = "";
   /* eslint-disable no-unused-vars */
   var openLeft = false;
   var openRight = false;
   /* eslint-enable no-unused-vars */

   // characters to strip from start and end of the input string
   var endRegExp = new RegExp("^[^\\w]+|[^\\w]+$", "g");

   // characters used to break up the input string into words
   var breakRegExp = new RegExp("[^\\w'-]+", "g");

   this.setEndRegExp = function (regex) {
      endRegExp = regex;
      return endRegExp;
   };

   this.setBreakRegExp = function (regex) {
      breakRegExp = regex;
      return breakRegExp;
   };

   this.setMatchType = function (type) {
      switch (type) {
         case "left":
            this.openLeft = false;
            this.openRight = true;
            break;

         case "right":
            this.openLeft = true;
            this.openRight = false;
            break;

         case "open":
            this.openLeft = this.openRight = true;
            break;

         default:
            this.openLeft = this.openRight = false;
      }
   };

   this.setRegex = function (input) {
      input = input.replace(endRegExp, "");
      input = input.replace(breakRegExp, "|");
      input = input.replace(/^\||\|$/g, "");
      if (input) {
         var re = "(" + input + ")";
         if (!this.openLeft) {
            re = "\\b" + re;
         }
         if (!this.openRight) {
            re = re + "\\b";
         }
         matchRegExp = new RegExp(re, "i");
         return matchRegExp;
      }
      return false;
   };

   this.getRegex = function () {
      var retval = matchRegExp.toString();
      retval = retval.replace(/(^\/(\\b)?|\(|\)|(\\b)?\/i$)/g, "");
      retval = retval.replace(/\|/g, " ");
      return retval;
   };

   // recursively apply word highlighting
   this.hiliteWords = function (node) {
      if (node === undefined || !node) return;
      if (!matchRegExp) return;
      if (skipTags.test(node.nodeName)) return;

      if (node.hasChildNodes()) {
         for (var i = 0; i < node.childNodes.length; i++)
            this.hiliteWords(node.childNodes[i]);
      }
      if (node.nodeType == 3) {
         // NODE_TEXT

         var nv, regs;

         if ((nv = node.nodeValue) && (regs = matchRegExp.exec(nv))) {
            if (!wordColor[regs[0].toLowerCase()]) {
               wordColor[regs[0].toLowerCase()] =
                  colors[colorIdx++ % colors.length];
            }

            var match = document.createElement(hiliteTag);
            match.appendChild(document.createTextNode(regs[0]));
            match.style.backgroundColor = wordColor[regs[0].toLowerCase()];
            match.style.color = "#000";

            var after = node.splitText(regs.index);
            after.nodeValue = after.nodeValue.substring(regs[0].length);
            node.parentNode.insertBefore(match, after);
         }
      }
   };

   // remove highlighting
   this.remove = function () {
      var arr = document.getElementsByTagName(hiliteTag),
         el;
      while (arr.length && (el = arr[0])) {
         var parent = el.parentNode;
         parent.replaceChild(el.firstChild, el);
         parent.normalize();
      }
   };

   // start highlighting at target node
   this.apply = function (input) {
      this.remove();
      if (input === undefined || !(input = input.replace(/(^\s+|\s+$)/g, ""))) {
         return;
      }
      if (this.setRegex(input)) {
         this.hiliteWords(targetNode);
      }
      return matchRegExp;
   };
}
