function SetFilteredToolList(allTools, currentFiltersArray) {
  let filteredToolIdList = [];
  let currentFilters = [...currentFiltersArray];

  // See if there are any filters selected yet and
  if (currentFilters.length <= 0) return [];

  for (const category in currentFilters) {
    if (currentFilters.length > 0) break;

    if (currentFilters[-1] === category) return [];
  }

  Object.keys(allTools).forEach((id) => {
    let hasTerms = [];

    currentFilters.forEach((chosenName) => {
      if (
        Object.hasOwn(allTools[id], chosenName) &&
        (allTools[id][chosenName].constructor === Boolean ||
          allTools[id][chosenName].constructor === Number)
      ) {
        hasTerms.push(allTools[id][chosenName] === chosenName);
      } else if (
        Object.hasOwn(allTools[id], chosenName) &&
        allTools[id][chosenName].length > 0
      ) {
        hasTerms.push(allTools[id][chosenName].includes(chosenName));
      }
    });

    if (!hasTerms.includes(false) && hasTerms.length > 0)
      filteredToolIdList.push(id);
  });

  return filteredToolIdList;
}

export default SetFilteredToolList;
