import { createSlice } from "@reduxjs/toolkit";

function InitState() {
  const initialState = {};
  initialState.jsCheatSheet = null;
  initialState.jsCheatSheetMetadata = null;
  initialState.updateJSCheatSheet = null;
  initialState.schema = null;
  initialState.reGatherJSCheatSheet = null;
  initialState.expandedItems = null;
  initialState.filteredToolsIds = null;
  initialState.currentFilters = null;
  initialState.jscsTopics = null;
  initialState.learnModeOn = null;
  initialState.inSearchMode = null;
  initialState.jsVersion = null;
  initialState.about = null;
  initialState.instructions = null;
  return initialState;
}

export const jsCheatSheetDataSlice = createSlice({
  name: "jsCheatSheetData",
  initialState: InitState(),
  reducers: {
    initState: (state, action) => {
      const payload = action.payload;

      state.jsCheatSheet = payload.jsCheatSheet;
      state.jsCheatSheetMetadata = payload.jsCheatSheetMetadata;
      state.updateJSCheatSheet = payload.updateJSCheatSheet;
      state.schema = payload.schema;
      state.reGatherJSCheatSheet = payload.reGatherJSCheatSheet;
      state.expandedItems = {};
      state.currentFilters = payload.currentFilters;
      state.filteredToolsIds = [];
      state.jscsTopics = payload.jscsTopics;
      state.learnModeOn = false;
      state.inSearchMode = false;
      state.jsVersion = payload.jsVersion;
      state.about = payload.about;
      state.instructions = payload.instructions;
    },

    updateOneJSCheatSheetItem: (state, action) => {
      const newJSCheatSheet = { ...state.jsCheatSheet };

      newJSCheatSheet[action.payload._id] = action.payload.item;
      newJSCheatSheet["count"] = Object.hasOwn(newJSCheatSheet, "count")
        ? [...newJSCheatSheet.count, action.payload._id]
        : [action.payload._id];
      state.jsCheatSheet = newJSCheatSheet;
    },

    updateJSCheatSheetDB: (state, action) => {
      if (action.payload && !Object.hasOwn(action.payload, "itemWithNewEdits"))
        alert(
          "There is a problem with missing data. Please alert the website admin.",
        );

      const output = {
        itemWithNewEdits: action.payload.itemWithNewEdits,
        user: action.payload.user ? action.payload.user : false,
        parentSection: action.payload.parentSection
          ? action.payload.parentSection
          : false,
      };

      state.updateJSCheatSheet = output;
    },
    resetUpdateJSCheatSheet: (state) => {
      state.updateJSCheatSheet = false;
    },
    updateSchema: (state, action) => {
      state.schema = { ...action.payload };
    },
    reGatherJSCheatSheet: (state, action) => {
      state.reGatherJSCheatSheet = action.payload;
    },
    toggleExpandedItems: (state, action) => {
      const newState = { ...state.expandedItems };
      const { section, id } = action.payload;

      if (Object.keys(newState).includes(section)) {
        if (newState[section].includes(id)) {
          newState[section].splice(newState[section].indexOf(id), 1);

          state.expandedItems = newState;
        } else {
          newState[section].push(id);
        }
      } else {
        newState[section] = [id];
      }

      state.expandedItems = { ...newState };
    },
    expandAllItems: (state, action) => {
      const newState = { ...state.expandedItems };
      const { section, idArray } = action.payload;

      newState[section] = [...idArray];

      state.expandedItems = { ...newState };
    },
    /// SET TOOL FILTERS //////////////////////////
    setToolFilterIds: (state, action) => {
      state.filteredToolsIds = [...action.payload];
    },
    /// ADD TO FILTERS /////////////////////////////
    addToToolFilters: (state, action) => {
      state.currentFilters = [...state.currentFilters, action.payload.value];
    },
    /// REMOVE FROM FILTERS ////////////////////////
    removeFromToolFilters: (state, action) => {
      let newState = [...state.currentFilters];

      newState.splice(newState.indexOf(action.payload.value), 1);
      state.currentFilters = newState;
    },

    /// CLEAR TOOL FILTER IDS ////////////////////
    clearToolFilterIds: (state) => {
      state.filteredToolsIds = [];
    },
    /// Learn Mode ////////////////////
    setLearnModeOn: (state, action) => {
      state.learnModeOn = action.payload;
    },

    /// Search Mode (open all articles) ////////////////////
    setInSearchMode: (state, action) => {
      state.inSearchMode = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const jsCheatSheetDataActions = jsCheatSheetDataSlice.actions;

export default jsCheatSheetDataSlice.reducer;
