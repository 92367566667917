import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import SlideButton from "../../UI/Buttons/SlideButton/SlideButton";
import Styles from "./LearnModeToggleButton.module.scss";
import { jsCheatSheetDataActions } from "../../store/jsCheatSheetDataSlice";

const LearnModeToggleButton = (props) => {
   const { learnModeOn } = useSelector((state) => state.jsCheatSheetData);
   const dispatch = useDispatch();

   function LearnModeToggleButtonHandler() {
      if (!learnModeOn) {
         dispatch(jsCheatSheetDataActions.setLearnModeOn(true));
         document.body.classList.add("in-learn-mode");
      } else {
         dispatch(jsCheatSheetDataActions.setLearnModeOn(false));
         document.body.classList.remove("in-learn-mode");
      }
   }

   return (
      <Fragment>
         {!props.bigDisplayButton && (
            <div
               key={"learn-mode-toggle" + "1"}
               className={
                  Styles["learn-mode-toggle-container"] +
                  " " +
                  (learnModeOn && Styles["learn-mode-on"])
               }
               onClick={LearnModeToggleButtonHandler}
            >
               <SlideButton
                  key={"learn-mode-toggle" + "1"}
                  label={learnModeOn ? "Learn Mode" : "Cheat Sheet"}
                  value={"LearnModeToggle"}
                  checked={learnModeOn ? true : false}
                  data={"LearnModeToggle"}
                  slideButtonTitleStyles={{
                     textAlign: "right",
                     fontSize: "10px"
                  }}
               />
            </div>
         )}
         {props.bigDisplayButton && (
            <button
               key={"learn-mode-toggle" + "1"}
               label={learnModeOn ? "Learn Mode On" : "Learn Mode Off"}
               className={
                  (learnModeOn && Styles["learn-mode-on"]) +
                  " " +
                  Styles["big-display-button"]
               }
               onClick={LearnModeToggleButtonHandler}
               data={"LearnModeToggle"}
            >
               {learnModeOn ? "Learn Mode" : "Cheat Sheet Mode"}
            </button>
         )}
      </Fragment>
   );
};

export default LearnModeToggleButton;
