import styles from "./JSLogo.module.scss";

function Header() {
   return (
      <div className={styles["sp-logo-container"]}>
         <a href="/" alt="">
            <div className={styles["title-wrap"]}>
               <h1 className={styles["spt-title"] + " " + styles["first-word"]}>
                  JS
               </h1>
            </div>
         </a>
      </div>
   );
}

export default Header;
