import { useState, useEffect, Fragment } from "react";
import Styles from "./Textarea.module.scss";
import useAddInputData from "../../../../../../Hooks/useAddInputData";
import DOMPurify from "dompurify";

// import TextareaAutosize from "react-textarea-autosize";
// import Highlighter from "react-highlight-words";

const Textarea = (props) => {
   const {
      passedKey,
      jsCheatSheetItemsObj,
      // section,
      parentMasterID,
      parentsParentKey,
      parentKey,
      displayConditions,
      unlockProtectedVisible,
      protectedHidden,
      emptyForm,
      editedField,
      setEditedField
   } = props;
   const key = passedKey;
   const [notes, setNotes] = useState("");
   const addInputData = useAddInputData();
   const onChangeNotes =
      () =>
      ({ target: { value } }) => {
         setNotes(value);
      };

   const onBlurHandler = (e) => {
      e.target.value = e.target.innerText;

      addInputData(e, {
         emptyForm,
         editedField,
         setEditedField
      });
   };

   useEffect(() => {
      if (jsCheatSheetItemsObj) setNotes(jsCheatSheetItemsObj);
   }, [jsCheatSheetItemsObj]);

   useEffect(() => {}, [notes]);

   return (
      <Fragment>
         <label
            id={
               parentMasterID +
               "-" +
               parentsParentKey +
               "-" +
               parentKey +
               "-" +
               key +
               "label"
            }
            htmlFor={parentKey + "-" + key}
            className={
               Styles[
                  "protectedVisible-" +
                     (displayConditions.protectedVisible.includes(key) &&
                        !unlockProtectedVisible.includes(parentMasterID))
               ]
            }
         >
            {key}
         </label>
         {/* {(unlockProtectedVisible.includes(parentMasterID) ||
        (section && section.includes("editor-in-modal"))) && (
        <TextareaAutosize
          id={
            parentMasterID +
            "-" +
            parentsParentKey +
            "-" +
            parentKey +
            "-" +
            key +
            "textarea"
          }
          key={parentKey + "-" + key}
          name={parentKey + "-" + key}
          data-category={jsCheatSheetItemsObj}
          placeholder={""}
          title={key}
          data-parentkey={parentKey}
          data-parentsparentkey={parentsParentKey ? parentsParentKey : ""}
          data-parentmasterid={parentMasterID}
                       data-protected-hidden={protectedHidden}
          onChange={onChangeNotes()}
          onBlur={onBlurHandler}
          defaultValue={notes}
          contentEditable={unlockProtectedVisible.includes(parentMasterID)}
          suppressContentEditableWarning={true}
          className="sizable-textarea"
        />
      )} */}
         {!props.setDangerousHTML && (
            <div
               id={
                  parentMasterID +
                  "-" +
                  parentsParentKey +
                  "-" +
                  parentKey +
                  "-" +
                  key +
                  "textarea"
               }
               key={parentKey + "-" + key}
               name={parentKey + "-" + key}
               data-category={jsCheatSheetItemsObj}
               placeholder={""}
               title={key}
               data-parentkey={parentKey}
               data-parentsparentkey={parentsParentKey ? parentsParentKey : ""}
               data-parentmasterid={parentMasterID}
               data-protected-hidden={protectedHidden}
               className="sizable-textarea"
            >
               {jsCheatSheetItemsObj}
               {notes}
            </div>
         )}
         {props.setDangerousHTML &&
            !unlockProtectedVisible.includes(parentMasterID) && (
               <Fragment>
                  <div
                     id={
                        parentMasterID +
                        "-" +
                        parentsParentKey +
                        "-" +
                        parentKey +
                        "-" +
                        key +
                        "textarea"
                     }
                     key={parentKey + "-" + key}
                     name={parentKey + "-" + key}
                     data-category={key}
                     placeholder={""}
                     title={key}
                     data-parentkey={parentKey}
                     data-parentsparentkey={
                        parentsParentKey ? parentsParentKey : ""
                     }
                     data-parentmasterid={parentMasterID}
                     data-protected-hidden={protectedHidden}
                     className="sizable-textarea"
                     dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(jsCheatSheetItemsObj)
                     }}
                  />
               </Fragment>
            )}
         {props.setDangerousHTML &&
            unlockProtectedVisible.includes(parentMasterID) && (
               <Fragment>
                  <div
                     id={
                        parentMasterID +
                        "-" +
                        parentsParentKey +
                        "-" +
                        parentKey +
                        "-" +
                        key +
                        "textarea"
                     }
                     key={parentKey + "-" + key}
                     name={parentKey + "-" + key}
                     data-category={key}
                     placeholder={""}
                     title={key}
                     data-parentkey={parentKey}
                     data-parentsparentkey={
                        parentsParentKey ? parentsParentKey : ""
                     }
                     data-parentmasterid={parentMasterID}
                     data-protected-hidden={protectedHidden}
                     className={"sizable-textarea " + Styles["in-edit-mode"]}
                     onChange={onChangeNotes()}
                     onBlur={onBlurHandler}
                     defaultValue={notes}
                     value={notes}
                     contentEditable={true}
                     dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(notes)
                     }}
                  />
               </Fragment>
            )}
      </Fragment>
   );
};

export default Textarea;
