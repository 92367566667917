const useSortList = () => {
  const outputFunction = (props) => {
    const { sortMethod, objectToBeSorted } = props;
    // if (objectToBeSorted) return objectToBeSorted; // TEMP TO BYPASS SORT
    if (!objectToBeSorted) return objectToBeSorted;
    const forNumberSort = [];
    const sortBy = sortMethod ? sortMethod : "slug";

    ////////////////////////////////////
    const sortNumbers = (fieldName, direction) => {
      const sortedIDOutputArray = [];
      let sortedArray = [];
      const name = fieldName.replace("-reverse", "");
      if (direction === "reverse") {
        sortedArray = Object.values(objectToBeSorted).sort(
          (v1, v2) => v1[name] * 1 - v2[name] * 1,
        );
      } else {
        sortedArray = Object.values(objectToBeSorted).sort(
          (v1, v2) => v2[name] * 1 - v1[name] * 1,
        );
      }
      sortedArray.forEach((item) => {
        sortedIDOutputArray.push(item.id);
      });
      return sortedIDOutputArray;
    };

    ////////////////////////////////////
    const sortWords = (fieldName, direction) => {
      const sortedIDOutputArray = [];
      let sortedArray = [];
      const name = fieldName.replace("-reverse", "");

      if (direction === "reverse") {
        sortedArray = Object.values(objectToBeSorted).sort((v1, v2) => {
          const name = sortBy.replace("-reverse", "");

          if (v1[name] < v2[name]) {
            return 1;
          }
          if (v1[name] > v2[name]) {
            return -1;
          }
          return 0;
        });
      } else {
        sortedArray = Object.values(objectToBeSorted).sort((v1, v2) => {
          if (v1[name] < v2[name]) {
            return -1;
          }
          if (v1[name] > v2[name]) {
            return 1;
          }
          return 0;
        });
      }

      sortedArray.forEach((item) => {
        sortedIDOutputArray.push(item.id);
      });

      return sortedIDOutputArray;
    };

    ////////////////////////////////////
    const direction = sortBy.includes("-reverse") ? "reverse" : "forward";
    let sortOrderIDArray = [];

    ////////////////////////////////////
    if (forNumberSort.includes(sortBy.replace("-reverse", ""))) {
      sortOrderIDArray = sortNumbers(sortBy, direction);
    } else {
      sortOrderIDArray = sortWords(sortBy, direction);
    }

    return sortOrderIDArray;
  };
  return outputFunction;
};

export default useSortList;
