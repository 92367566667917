import axios from "axios";

/// GET THE JS CHEAT SHEET ITEMS /////////////////////////////
export const jsCheatSheetData = async () => {
   try {
      const myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
         method: "GET",
         headers: myHeaders,
         redirect: "follow"
      };

      const response = await fetch(
         "https://glassinteractive.com/wp-json/wp/v2/posts?tags=221&&per_page=100",
         requestOptions
      );

      const result = await response.json();

      return result;
   } catch (error) {
      console.error(error);
      return [];
   }

   // const axiosConfig = {
   //   headers: {
   //     // "Content-Type": "application/json",
   //     // Authorization: "JWT " + user.token,
   //   },
   //   timeout: 300000,
   // };

   // try {
   //   const res = await axios.get("/posts?tags=221&&per_page=100", axiosConfig);

   //   return res.data;
   // } catch (err) {
   //   console.log(
   //     "%cERROR--->:",
   //     "color:#f0f0ef;background:#ff0000;padding:32px;border-radius:0 25px 25px 0",
   //     err,
   //   );
   //   return [];
   // }
};

/// GET ONE JS CHEAT SHEET ITEM /////////////////////////////
export const getQuestionBy_Id = async (user, question_Id) => {
   let axiosConfig = null;

   if (user) {
      axiosConfig = {
         headers: {
            "Content-Type": "text/plain",
            Authorization: "JWT " + user.token
         },
         timeout: 60000
      };
   }

   const res = await axios.post(
      "/api/jsCheatSheet/" + question_Id,
      user,
      axiosConfig
   );
   return res.data;
};

/// SAVE ONE /////////////////////////////////////
export async function addDocToDB(userAndDataObject) {
   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         Authorization: "JWT " + userAndDataObject.user.token
      }
   };

   const response = await axios
      .post(`/api/jsCheatSheet/add/`, userAndDataObject, axiosConfig)
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(
            "%cERROR:",
            "color:#f0f0ef;background:#ff0000;padding:32px;border-radius:0 25px 25px 0",
            err
         );
         return err;
      });
   return response;
}

/// SAVE MANY /////////////////////////////////
export async function saveManyJSCheatSheetItems(userAndDataObject) {
   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         Authorization: "JWT " + userAndDataObject.user.token
      }
   };

   const response = await axios
      .post(`/api/jsCheatSheet/add-many/`, userAndDataObject, axiosConfig)
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(
            "%cERROR:",
            "color:#f0f0ef;background:#ff0000;padding:32px;border-radius:0 25px 25px 0",
            err
         );
         return err;
      });
   return response;
}

/// UPDATE //////////////////////////////////
export async function updateAJSCheatSheetItem(dataObj, user) {
   if (!Object.hasOwn(dataObj, "identifier")) {
      throw Error(
         "This item appears to be incomplete. Contact the site admin and provide this error code: SPDB-MIS-IDENT"
      );
   }
   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         Authorization: "JWT " + user.token
      }
   };

   const response = await axios
      .post(`/api/jsCheatSheet/update/`, { dataObj }, axiosConfig)
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(
            "%cERROR:",
            "color:#f0f0ef;background:#ff0000;padding:32px;border-radius:0 25px 25px 0",
            err
         );

         return err;
      });
   return response;
}

/// DELETE ////////////////////////////////
export async function deleteDocFromDb(id, user) {
   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         Authorization: "JWT " + user.token
      }
   };
   const response = await axios
      .get(`/api/jsCheatSheet/${id}/delete/`, axiosConfig)
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(
            "%cERROR:",
            "color:#f0f0ef;background:#ff0000;padding:32px;border-radius:0 25px 25px 0",
            err
         );
         return err;
      });
   return response;
}

/// DELETE ALL ///////////////////////////
export async function deleteAllStudyTopics(user) {
   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         Authorization: "JWT " + user.token
      }
   };
   const response = await axios
      .get(`/api/jsCheatSheet/deleteAll/`, axiosConfig)
      .then((res) => {
         return res;
      })
      .catch((err) => {
         console.log(
            "%cERROR:",
            "color:#f0f0ef;background:#ff0000;padding:32px;border-radius:0 25px 25px 0",
            err
         );
         console.log(
            "%cERROR:",
            "color:#f0f0ef;background:#ff0000;padding:32px;border-radius:0 25px 25px 0",
            err
         );
         return err;
      });
   return response;
}

/// GET JS CHEAT SHEET ITEM SCHEMA //////////////
export async function getSchemaForJSCheatSheetItem() {
   const axiosConfig = {
      headers: {
         "Content-Type": "application/json"
      }
   };

   const output = await axios
      .get(`/posts?tags=221&&per_page=1`, axiosConfig)
      .then((res) => {
         return res.data;
      })
      .catch((err) => {
         const output = { response: { request: {} } };
         console.log(
            "%cERROR:",
            "color:#f0f0ef;background:#ff0000;padding:32px;border-radius:0 25px 25px 0",
            err
         );

         if (err.code && err.code === "ERR_NETWORK") {
            output.response.status = 500;
            output.response.statusText = err.message;
            output.response.request.responseURL = err.config.baseURL;
         }
         return output.response;
      });

   return output;
}
