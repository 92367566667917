// import axios from "axios";

/// GET THE JS NEWS FEED /////////////////////////////
export const jsNewsFeed = async () => {
   try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");

      const requestOptions = {
         method: "GET",
         headers: myHeaders,
         redirect: "follow"
      };

      let urlToFetch =
         "https://corsproxy.io/?https://cprss.s3.amazonaws.com/javascriptweekly.com.xml";
      // let urlToFetch =
      //    process.env.NODE_ENV === "development"
      //       ? "http://dev2-glassinteractive.com/js-news-feed-json/"
      //       : "https://glassinteractive.com/js-news-feed-json";

      const response = await fetch(urlToFetch, requestOptions);
      const text = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, "text/xml");
      const xml = doc.documentElement;
      const siteTitle = xml.getElementsByTagName("title")[0].innerHTML;
      const siteLink = xml.getElementsByTagName("link")[0].innerHTML;
      const articlesRAW = xml.getElementsByTagName("item");
      const articleArray = [];
      for (const value of articlesRAW) {
         const outputObject = {};
         for (const item of value.childNodes) {
            if (!item.innerHTML) continue;
            outputObject[item.tagName] = item.childNodes[0].data;
         }
         articleArray.push(outputObject);
      }

      const output = {
         siteTitle,
         siteLink,
         articles: articleArray
      };

      return output;
   } catch (err) {
      console.log(
         "%c %cline:64%c-ERROR: ",
         "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
         "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
         "color:#fff;background:rgb(179, 214, 110);padding:3px;border-radius:2px",
         err
      );
      return false;
   }
};
// export const jsNewsFeed = async () => {
//    const axiosConfig = {
//       headers: {
//          "Content-Type": "application/json",
//          Authorization: ""
//       },
//       timeout: 300000
//    };
//    try {
//       const res = await axios.get(
//          " https://corsproxy.io/?" +
//             encodeURIComponent(
//                "https://glassinteractive.com/js-news-feed-json"
//             ),
//          axiosConfig
//       );
//       return res.data;
//    } catch (err) {
//       console.log(
//          "%cERROR--->:",
//          "color:#f0f0ef;background:#ff0000;padding:32px;border-radius:0 25px 25px 0",
//          err
//       );
//       return false;
//    }
// };

// PACKGE.JSON
//     "start": "HOST=www.javascript-cheatsheet.glassinteractive.com react-scripts start",
