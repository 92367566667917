import styles from "./About.module.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { contentDataActions } from "../../store/contentDataSlice";
import PushButton from "../../UI/Buttons/PushButton/PushButton";
import DOMPurify from "dompurify";

const About = () => {
   const aboutIsActive = useSelector(
      (state) => state.contentData.aboutIsActive
   );
   const { about } = useSelector((state) => state.jsCheatSheetData);
   const dispatch = useDispatch();
   const [aboutData, setAboutData] = useState({});
   const aboutButtonHandler = () => {
      dispatch(contentDataActions.setAboutIsActive(!aboutIsActive));
   };

   useEffect(() => {
      if (about) {
         setAboutData(about);
      }
   }, [about]);

   return (
      <div className={styles["about-container"]}>
         {aboutData && (
            <div className={styles["about-text-container"]}>
               <div className={styles["title-container"]}>
                  {/* <div className={styles["image-wrap"]}>
                <img
                  src={aboutData.imageOneURL}
                  className={
                    styles["image"] +
                    " " +
                    styles["image-one"] +
                    " " +
                    styles["content"]
                  }
                  alt={aboutData.title + " first image "}
                />
              </div> */}

                  {aboutData.title && (
                     <div className={styles["sub-title-background"]}>
                        <a
                           name="about"
                           href="#about"
                           className={styles["anchor-page-bookmark"]}
                        >
                           About page bookmark
                        </a>
                        <br />
                        <PushButton
                           styles={{
                              position: "absolute",
                              top: "-2em",
                              right: "0"
                           }}
                           inputOrButton="button"
                           colorType="secondary"
                           size=""
                           onClick={aboutButtonHandler}
                        >
                           Close
                        </PushButton>
                        <h3 className={`sub-title ${styles["sub-title"]}`}>
                           {aboutData.title}
                        </h3>
                     </div>
                  )}
               </div>

               {aboutData.content && (
                  <div
                     className={
                        styles["text"] +
                        " " +
                        styles["text-one"] +
                        " " +
                        styles["content"]
                     }
                  >
                     <p
                        dangerouslySetInnerHTML={{
                           __html: DOMPurify.sanitize(aboutData.content)
                        }}
                     />
                  </div>
               )}
            </div>
         )}
      </div>
   );
};

export default About;
