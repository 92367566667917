import { useSelector, useDispatch } from "react-redux";
import styles from "./Navbar.module.scss";
import SocialConnectMenu from "../SocialConnectMenu/SocialConnectMenu";
import { contentDataActions } from "../../store/contentDataSlice";

function Navbar(props) {
  const dispatch = useDispatch();
  const aboutIsActive = useSelector((state) => state.contentData.aboutIsActive);
  const instructionsIsActive = useSelector(
    (state) => state.contentData.instructionsIsActive,
  );
  const linkOnClick = props.linkOnClick;
  const aboutButtonHandler = () => {
    if (linkOnClick) linkOnClick();
    dispatch(contentDataActions.setAboutIsActive(!aboutIsActive));
  };
  const instructionsButtonHandler = () => {
    if (linkOnClick) linkOnClick();
    dispatch(contentDataActions.setInstructionsIsActive(!instructionsIsActive));
  };

  return (
    <div className={styles["nav-container"]}>
      {props.navLinks &&
        props.navLinks.map((item) => (
          <a
            key={item._id + item.type}
            href={`#${item.type}`}
            alt=""
            className={styles["small-header-nav"]}
          >
            {item.titleOnNavMenu ? item.titleOnNavMenu : item.title}
          </a>
        ))}

      {/* <a
        href="#demo"
        alt=""
        className={styles["small-header-nav"]}
        onClick={linkOnClick}
      >
        Demo
      </a> */}
      <a
        href="#instructions"
        alt=""
        className={styles["small-header-nav"]}
        onClick={instructionsButtonHandler}
      >
        How To
      </a>

      <button
        className={styles["small-header-nav"]}
        onClick={aboutButtonHandler}
      >
        About
      </button>

      {props.socialIsActive && <SocialConnectMenu />}
    </div>
  );
}

export default Navbar;
