import { useState, useEffect, Fragment } from "react";
import { shallowEqual, useSelector } from "react-redux";
import styles from "./JSCheatSheetItems.module.scss";
import JSCheatSheetItemsList from "./JSCheatSheetItemsList/JSCheatSheetItemsList";
// import FilteredJSCheatSheetItems from "./FilteredJSCheatSheetItems/FilteredJSCheatSheetItems.jsx";
import displayConditions from "../../data/displayConditionsObj.js";
import studyItemSortOptions from "../../data/studyItemSortOptions.json";
import useInitJSCheatSheetItems from "../../Hooks/useInitJSCheatSheetItems.js";
import PushButton from "../../UI/Buttons/PushButton/PushButton.js";
// import { toTitleCase } from "../../Hooks/utility.js";
import { getSchemaForContentItem } from "../../storage/contentDB.js";
// import CollapsibleElm from "../../UI/CollapsibleElm/CollapsibleElm.jsx";
import useSortList from "../../Hooks/useSortList.js";
import useAssembleJSCheatSheetList from "../../Hooks/useAssembleJSCheatSheetList.js";
import BarLoader from "../../UI/Loaders/BarLoader/BarLoader.js";

const JSCheatSheetItems = (props) => {
  const user = useSelector((state) => state.auth.user);
  const { jsCheatSheet, jsCheatSheetMetadata, schema } = useSelector(
    (state) => state.jsCheatSheetData,
  );
  const [sortMethod, setSortMethod] = useState("title");
  const [refresh, setRefresh] = useState(0);
  const [changeListArray, setChangeListArray] = useState(false);
  const [hideAllSubGoals, setHideAllSubGoals] = useState(true);
  const [showListResetButton, setShowListResetButton] = useState(false);
  const id = props.id;
  const typeName = props.type;
  const dataObjForEdit = props.dataObjForEdit;
  const initJSCheatSheetItems = useInitJSCheatSheetItems();
  const [allJSCheatSheetItems, setAllJSCheatSheetItems] = useState(
    props.allJSCheatSheetItems,
  );
  const [formInputData, setFormInputData] = useState({});
  const [sortOrder, setSortOrder] = useState(false);
  const [newFormJSX, setNewFormJSX] = useState(false);
  const allFormInputData = useSelector(
    (state) => state.formInputData,
    shallowEqual,
  );
  const sortList = useSortList();
  const assembleJSCheatSheetList = useAssembleJSCheatSheetList();
  const [delayRender, setDelayRender] = useState(true);
  let outputName =
    dataObjForEdit &&
    dataObjForEdit[id] &&
    Object.hasOwn(dataObjForEdit[id], "title") ? (
      <Fragment key={dataObjForEdit[id].title + id}>
        <div key={dataObjForEdit[id].title}>{dataObjForEdit[id].title}</div>
        <div key={id + dataObjForEdit[id].title}>{id}</div>
      </Fragment>
    ) : typeName ? (
      typeName + "s"
    ) : (
      id
    );

  if (outputName === "posts") outputName = "Posts";

  ////////////////////////////////////////////////////////////////////////
  /// EFFECTS
  ////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setTimeout(setDelayRender(false), 2000);
  }, []);

  useEffect(() => {
    setTimeout(setDelayRender(false), 2000);
  }, [formInputData]);

  useEffect(() => {
    setDelayRender(true);

    initJSCheatSheetItems({
      id,
      sortMethod: sortMethod,
      typeArray: [typeName],
      dataObjForEdit,
      getSchemaForContentItem,
      allJSCheatSheetItems,
      setAllJSCheatSheetItems,
      setFormInputData,
    }).then((spData) => {
      if (spData) {
        const { groomedAllItemOutput, sortedIDArray } = spData;

        setAllJSCheatSheetItems(groomedAllItemOutput);
        setSortOrder(sortedIDArray);
        setFormInputData(groomedAllItemOutput);
      }
      setTimeout(setDelayRender(false), 2000);
    });
  }, [dataObjForEdit, user]);

  useEffect(() => {
    if (formInputData) {
      const enumerableFormInputData = {};
      for (const [key, value] of Object.entries(formInputData)) {
        enumerableFormInputData[key] = value;
      }

      const sortOrderArray = sortList({
        sortMethod: sortMethod,
        objectToBeSorted: { ...enumerableFormInputData },
      });

      // setFormInputData(sortedGroomedOutput);
      setSortOrder(sortOrderArray);

      setRefresh(refresh + 1);
    }
  }, [sortMethod]);

  useEffect(() => {
    if (changeListArray) {
      const keysToUseArray = getSchemaForContentItem
        ? [...Object.keys(schema)]
        : [];

      const { groomedOutput } = assembleJSCheatSheetList({
        typeArray: changeListArray,
        keysToUseArray,
        dataObjForEdit,
        allJSCheatSheetItems: jsCheatSheet,
      });

      const sortOrderArray = sortList({
        sortMethod: sortMethod,
        objectToBeSorted: { ...groomedOutput },
      });
      setSortOrder(sortOrderArray);
      // setFormInputData(sortedGroomedOutput);
    }
  }, [changeListArray]);

  // Clear new forms after processing.
  useEffect(() => {
    if (!allFormInputData.allNewForms) setNewFormJSX(false);
  }, [allFormInputData.allNewForms]);

  ////////////////////////////////////////////////////////////////////////
  /// HANDLERS
  ////////////////////////////////////////////////////////////////////////

  const sortMethodButtonHandler = (e) => {
    setSortMethod(e.target.value);
  };

  const showAllItemsButtonHandler = () => {
    if (jsCheatSheetMetadata && Object.hasOwn(jsCheatSheetMetadata, "type")) {
      setShowListResetButton(true);
      setChangeListArray([...jsCheatSheetMetadata.type]);
      setHideAllSubGoals(false);
    }
  };

  const showDefaultItemsButtonHandler = () => {
    setShowListResetButton(false);
    setChangeListArray(["step"]);
    setHideAllSubGoals(true);
  };

  ////////////////////////////////////////////////////////////////////////
  /// Output
  ////////////////////////////////////////////////////////////////////////
  return (
    <Fragment key={"Welcomeandgoals"}>
      {newFormJSX && (
        <div
          key={id + typeName}
          id="new-form-modal"
          className={styles["new-form-modal"]}
          type={typeName}
        >
          <form key={id}>{newFormJSX}</form>
        </div>
      )}

      {formInputData &&
        Object.keys(formInputData).length >= 0 &&
        Object.hasOwn(jsCheatSheetMetadata, "id") &&
        jsCheatSheetMetadata.id.length > 0 && (
          <ul
            key={id + typeName}
            data-marker="STUDYPLAN-ITEMS"
            data-section={id}
            id={id}
            type={typeName}
            data-hiddeitems={"" + hideAllSubGoals}
            className={
              styles["jsCheatSheet-items-group"] +
              " " +
              styles["group-" + id] +
              " " +
              styles[id]
            }
          >
            {delayRender && <BarLoader />}
            <a
              name={"section-" + typeName}
              className={styles["section-anchor"]}
            ></a>
            <div
              className={
                styles["title-button-container"] +
                " " +
                styles["" + outputName.toLowerCase()]
              }
            >
              <h2 className={styles["group-title"] + " " + styles[id]}>
                {outputName}{" "}
              </h2>
            </div>

            {
              // outputName.toLowerCase().includes("posts") &&
              //   Object.keys(formInputData).length > 0 && (
              //     <div
              //       key={id + "history-list-data-section"}
              //       data-section="history-list-data-section"
              //       id={id}
              //       className={
              //         styles["jsCheatSheet-history-list-container"] +
              //         " " +
              //         styles["group-" + id] +
              //         " " +
              //         styles[id]
              //       }
              //     >
              //       <div key={id} className={styles["history-list-inner-wrap"]}>
              //         <CollapsibleElm
              //           key={id + "-collapsible-elm"}
              //           elmId={id + "-collapsible-elm"}
              //           styles={{
              //             position: "relative",
              //             maxWidth: "100%",
              //           }}
              //           maxHeight={"0"}
              //           s
              //           inputOrButton="button"
              //           buttonStyles={{}}
              //           colorType="secondary"
              //           data=""
              //           size="small"
              //           buttonTextOpened={"Close Completed Steps"}
              //           buttonTextClosed={"Open Completed Steps"}
              //           open={false}
              //           showBottomGradient={true}
              //         >
              //           <FilteredJSCheatSheetItems
              //             key={id + "Filtered Items"}
              //             filterKey={"date"}
              //             section={"completed-items"}
              //             sectionTitle={"Completed"}
              //             sectionSubText={"All completed steps and goals"}
              //             messageIfNone="There are no completed items. Time to get to work! :)"
              //             jsCheatSheetSet={formInputData}
              //             allJSCheatSheetItems={allJSCheatSheetItems}
              //             displayConditions={displayConditions}
              //             user={props.user}
              //           />
              //         </CollapsibleElm>
              //       </div>
              //       {/* <div className={styles["history-list-inner-wrap"]}>
              //         <CollapsibleElm
              //           key={id + "-collapsible-elm"}
              //           elmId={id + "-collapsible-elm"}
              //           styles={{
              //             position: "relative",
              //             maxWidth: "100%",
              //           }}
              //           maxHeight={"0"}
              //           s
              //           inputOrButton="button"
              //           buttonStyles={{}}
              //           colorType="secondary"
              //           data=""
              //           size="small"
              //           buttonTextOpened={"Close Steps Needing Review"}
              //           buttonTextClosed={"Open Steps Needing Review"}
              //           open={false}
              //           showBottomGradient={true}
              //         >
              //           <FilteredJSCheatSheetItems
              //             key={id + "Filtered Items"}
              //             filterKey={"markforreview"}
              //             section={"review-items"}
              //             sectionTitle={"Items for Review"}
              //             sectionSubText={
              //               "All goals or steps that have been marked for review."
              //             }
              //             messageIfNone='There are no items for review right now. If you completed a step or a goal, but feel you need to review the material at a later date to ensure understanding, use the "Mark for Review" button at the bottom of each item to set it for later followup.'
              //             jsCheatSheetSet={formInputData}
              //             allJSCheatSheetItems={allJSCheatSheetItems}
              //             displayConditions={displayConditions}
              //             user={props.user}
              //           />
              //         </CollapsibleElm>
              //       </div> */}
              //     </div>
              //   )
            }

            <div
              key={id + "-collapsible-elm"}
              // elmId={id + "-collapsible-elm"}
              // styles={{
              //   position: "relative",
              //   maxWidth: "100%",
              // }}
              // maxHeight={
              //   props.maxCollapsableElmHeight
              //     ? props.maxCollapsableElmHeight
              //     : ""
              // }
              // inputOrButton="button"
              // buttonStyles={{
              //   margin: "0 auto",
              //   padding: "0.5em 2em",
              //   letterSpacing: "0.25em",
              //   fontVariant: "small-caps",
              //   transform: "translateY(0%)",
              //   transition: "0.7s all ease",
              //   minWidth: "80%",
              //   maxWidth: "80%",
              //   textAlign: "center",
              //   display: "flex",
              //   alignItems: "center",
              //   borderRadius: "0 0 50px 50px",
              //   fontFamily: "Good Times RG",
              // }}
              // colorType="primary"
              // data=""
              // size="small"
              // buttonTextOpened={"- Close All " + toTitleCase(outputName) + " -"}
              // buttonTextClosed={"- Open All " + toTitleCase(outputName) + " -"}
              // open={false}
              // showBottomGradient={id === "jsCheatSheet" && true}
              // recheckHeight={
              //   typeName && Object.hasOwn(expandedItems, typeName)
              //     ? expandedItems[typeName].length
              //     : Object.keys(expandedItems).length
              // }
            >
              {Object.keys(formInputData).length > 0 && (
                <div
                  className={styles["sort-button-container"]}
                  type={typeName}
                >
                  {!props.hideShowAllButton && (
                    <div
                      key={id + "show-everything"}
                      id="list-button-container"
                      className={styles["list-button-container"]}
                    >
                      {!showListResetButton && (
                        <Fragment>
                          <PushButton
                            key={id + "show-goals-steps-holds"}
                            inputOrButton="button"
                            id={"create-entry-btn" + id}
                            colorType="secondary"
                            styles={{}}
                            value={id}
                            parentmasterid={id}
                            data=""
                            size="small"
                            onClick={showAllItemsButtonHandler}
                          >
                            Show All Goals, Steps & Holds
                          </PushButton>
                        </Fragment>
                      )}
                      {showListResetButton && (
                        <PushButton
                          key={id + "show-only-steps"}
                          inputOrButton="button"
                          id={"create-entry-btn" + id}
                          colorType="secondary"
                          styles={{}}
                          value={id}
                          parentmasterid={id}
                          data=""
                          size="small"
                          onClick={showDefaultItemsButtonHandler}
                        >
                          Show Only Steps
                        </PushButton>
                      )}
                    </div>
                  )}
                  <label
                    key={id + sortMethod}
                    className={styles["sort-button-wrap"]}
                  >
                    Sort by:
                    <select
                      key={id + sortMethod}
                      className={styles["new-form-button"]}
                      data-parentmasterid={id}
                      onChange={sortMethodButtonHandler}
                      value={sortMethod}
                    >
                      {Object.entries(studyItemSortOptions)
                        .sort((a, b) =>
                          a[1].toLowerCase().localeCompare(b[1].toLowerCase()),
                        )
                        .map((entry) => (
                          <Fragment key={"1+r" + entry[0] + entry[1]}>
                            {" "}
                            <option
                              key={"1" + entry[0] + entry[1]}
                              value={entry[0]}
                            >
                              {entry[1]}
                            </option>{" "}
                            <option
                              key={"r" + entry[0] + entry[1]}
                              value={entry[0] + "-reverse"}
                            >
                              {entry[1] + " (reversed)"}
                            </option>
                          </Fragment>
                        ))}
                    </select>{" "}
                  </label>
                </div>
              )}

              {formInputData &&
                refresh &&
                Object.keys(formInputData).length > 0 && (
                  <JSCheatSheetItemsList
                    key={"spi-list" + id}
                    jsCheatSheetItemsObj={formInputData}
                    sortOrder={sortOrder}
                    allJSCheatSheetItems={allJSCheatSheetItems}
                    parentKey={false}
                    parentsParentKey={false}
                    parentMasterID={false}
                    displayConditions={displayConditions.formWithPreFilledData}
                    user={props.user}
                    section={id}
                    onlyList={props.onlyList}
                    noEditButton={props.noEditButton}
                    refresh={refresh}
                    parentMasterType={props.parentMasterType}
                  />
                )}
            </div>
          </ul>
        )}
    </Fragment>
  );
};

export default JSCheatSheetItems;
