import { Fragment } from "react";
import Styles from "./Instructions.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { contentDataActions } from "../../store/contentDataSlice";
import PushButton from "../../UI/Buttons/PushButton/PushButton";
import DOMPurify from "dompurify";

const Instructions = () => {
   const dispatch = useDispatch();
   const instructionsIsActive = useSelector(
      (state) => state.contentData.instructionsIsActive
   );
   const { instructions } = useSelector((state) => state.jsCheatSheetData);
   const instructionsButtonHandler = () => {
      dispatch(
         contentDataActions.setInstructionsIsActive(!instructionsIsActive)
      );
   };

   return (
      <div className={Styles["instructions-container"]}>
         {instructions && (
            <Fragment>
               {" "}
               <PushButton
                  styles={{ position: "absolute", top: "-2em", right: "0" }}
                  inputOrButton="button"
                  colorType="primary"
                  size=""
                  onClick={instructionsButtonHandler}
               >
                  Close
               </PushButton>
               <h3 className={Styles["title"]}>{instructions.title}</h3>
               <p
                  className={Styles["content"]}
                  dangerouslySetInnerHTML={{
                     __html: DOMPurify.sanitize(instructions.content)
                  }}
               ></p>
            </Fragment>
         )}
      </div>
   );
};

export default Instructions;
