import { useEffect, Fragment } from "react";
import styles from "./FilterTools.module.scss";
import { useSelector, useDispatch } from "react-redux";
// import SlideButton from "../../UI/Buttons/SlideButton/SlideButton";
import PushButton from "../../UI/Buttons/PushButton/PushButton";
import LearnModeToggleButton from "../../Components/LearnModeToggleButton/LearnModeToggleButton";
import { escapeHtml } from "../../Hooks/utility";
import SetFilteredToolIdList from "../../Hooks/SetFilteredToolList";
import { jsCheatSheetDataActions } from "../../store/jsCheatSheetDataSlice";
// import CollapsibleElm from "../../UI/CollapsibleElm/CollapsibleElm";
import { toTitleCase } from "../../Hooks/utility";
import filtersNamesSortOrder from "../../data/filtersNamesSortOrder";

function FilterTools() {
   const jsCheatSheetData = useSelector((state) => state.jsCheatSheetData);
   const user = useSelector((state) => state.auth.user);
   const dispatch = useDispatch();
   const { currentFilters, jsCheatSheet, jscsTopics } = jsCheatSheetData;
   const filtersSortedSet = new Set(
      filtersNamesSortOrder.map((filterName) => filterName)
   );
   jscsTopics.forEach((topic) => filtersSortedSet.add(topic));
   const filtersSorted = Array.from(filtersSortedSet);

   ////////////////////////////////////////
   /// EFFECTS
   ////////////////////////////////////////
   useEffect(() => {
      dispatch(jsCheatSheetDataActions.clearToolFilterIds);
      const filteredToolIdList = SetFilteredToolIdList(
         jsCheatSheet,
         currentFilters
      );

      dispatch(jsCheatSheetDataActions.setToolFilterIds(filteredToolIdList));
   }, [currentFilters, jsCheatSheet, dispatch]);

   useEffect(() => {
      dispatch(jsCheatSheetDataActions.clearToolFilterIds);
   }, [user, dispatch]);

   ////////////////////////////////////////
   /// HANDLERS
   ////////////////////////////////////////
   function filterButtonHandler(e) {
      const value = escapeHtml(e.target.value);

      if (currentFilters.includes(value)) {
         dispatch(
            jsCheatSheetDataActions.removeFromToolFilters({
               value: value
            })
         );
      } else {
         dispatch(
            jsCheatSheetDataActions.addToToolFilters({
               value: value
            })
         );
         SetFilteredToolIdList(jsCheatSheet, currentFilters);
      }

      // dispatch(jsCheatSheetDataActions.goToToolRows());
      // FilterTools(jsCheatSheetData);
   }

   ////////////////////////////////////////
   /// OUTPUT
   ////////////////////////////////////////
   return (
      <Fragment>
         <h2
            key={"tool-filter-2"}
            className={`section-title ${styles["main-title"]}`}
         >
            Filters
         </h2>
         <LearnModeToggleButton />
         <div
            key={"tool-filter-1"}
            id="tool-filter"
            className={styles.outerwrap + " " + styles["article-filter"]}
         >
            {jsCheatSheet && !Object.hasOwn(jsCheatSheet, "error") && (
               <Fragment>
                  <div
                     key={"tool-filter-3"}
                     className={styles["slide-button-wrap"]}
                  >
                     {currentFilters &&
                        filtersSorted.map((topic) => {
                           if (
                              topic === "name" ||
                              topic.includes("URL") ||
                              topic === "notes"
                           )
                              return;

                           return (
                              <div
                                 key={topic + "30"}
                                 className={styles["slide-button-inner-wrap"]}
                              >
                                 <PushButton
                                    key={topic + "30"}
                                    inputOrButton="button"
                                    colorType="primary"
                                    size="medium"
                                    value={topic}
                                    active={false}
                                    onClick={filterButtonHandler}
                                    checked={currentFilters.includes(topic)}
                                    data={topic}
                                    user={user.email}
                                    styles={{
                                       width: "90%",
                                       fontSize: "75%",
                                       background:
                                          currentFilters.includes(topic) &&
                                          "radial-gradient( 50% 50% at 50% 50%, var(--jscs-color-accent-2-light) 0%, var(--jscs-color-accent-2) 100%)"
                                    }}
                                 >
                                    {toTitleCase(
                                       topic
                                          .replace("jscs-", "")
                                          .replaceAll("-", " "),
                                       true
                                    )}
                                 </PushButton>
                              </div>
                           );
                        })}
                  </div>
               </Fragment>
            )}
         </div>
      </Fragment>
   );
}

export default FilterTools;
