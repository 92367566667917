import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PushButton from "../../UI/Buttons/PushButton/PushButton";
import Styles from "./ToTopButton.module.scss";

const ToTopButton = () => {
   const [hideButton, setHideButton] = useState(true);
   const { welcomeScrollPosition } = useSelector(
      (state) => state.scrollPosition
   );

   const toTopButtonHandler = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
   };

   useEffect(() => {
      if (welcomeScrollPosition) {
         setHideButton(welcomeScrollPosition.top >= -180);
      }
   }, [welcomeScrollPosition]);

   return (
      <div
         className={
            Styles["to-top-container"] +
            " " +
            (hideButton && Styles["hide-button"])
         }
      >
         <PushButton
            key="to-top-button"
            inputOrButton="button"
            id={"to-top-button"}
            colorType="primary"
            data=""
            size="small"
            onClick={toTopButtonHandler}
            // styles={{
            //    background: "var(--jscs-color-accent-2)",
            //    color: "var(--jscs-color-background-warm)",
            //    fontWeight: "700"
            // }}
         >
            &uarr; Back to Top
         </PushButton>
      </div>
   );
};

export default ToTopButton;
